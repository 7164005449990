import React from "react";
// Customizable Area Start
import {
    Grid,
    Typography,
    Box,
    Divider,  
    Avatar,
    CardMedia,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    InputLabel,
    TextField,
    IconButton,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    TextareaAutosize,Tooltip
 } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';

// Customizable Area End

// Customizable Area Start
import { ThemeProvider, createTheme, withStyles } from "@material-ui/core/styles";
import {
     chat,
     workicon,
     download,
     projectCloseIcon,
     disputeIcon,
     deleteFileIcon,
} from './assets'
import { milestoneCreatedIcon } from "../../scheduling/src/assets";
import { Timeline, TimelineOppositeContent, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@material-ui/lab';
import moment from 'moment';
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import StarRatings from 'react-star-ratings';
export const trackingTheme = createTheme({
    palette: {
      },
    typography: {
        fontFamily:'inter',
      },
      overrides: {
        MuiFormLabel: {
          root: {
            color:"#8083a3",
            fontSize: '14px',
            fontWeight: 500,
            "&.Mui-focused": {
              color: "#8083a3",
            },
          },
          
      } , 
        MuiRadio: {
          root: {
            color: '#99cb51',
            width: '1rem',
            height: '1rem',
          },
          colorSecondary: {
            color: '#99cb51',
            '&$checked': {
              color: '#99cb51',
            },
           },
        },
        MuiInputBase: {
          input: {
              color: '#8083a3',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 400,
              },
          },
   }  
});
const StyledRadioButton = withStyles({
  root: {
      color: "#e4e6e8",
      '&$checked': {
          color: "#ec952d",
      },
  },
  checked: {},
})(Radio);

const StyledReviewFeedbackField = withStyles({
  root: {
    maxWidth: '100%',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    color: '#8083a3',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& input::placeholder": {
      fontFamily: 'Inter',
      fontSize: '14',
      fontWeight: 400,
      color: '#8083a3'
    },
  }
})(TextField);

// Customizable Area End

import PrjDetailsController, {
  Props, configJSON,
  HistoryDetails,
  FileDetails,
  DisputeDetails,
  DisputeRaisedDetails,
  RefundDetails,
  MilestoneDisputeDetails,
} from "./PrjDetailsController.web";
import { getFreelancerMulitCurrencyHTML } from "../../../components/src/utill/getMultiCurrencyHtml";
import { TimeOnly,replaceBidToQuote } from "../../../components/src/utill/TimeAndDate.web";

export class PrjDetails extends PrjDetailsController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderReasonGrid = (item: string) => {
    const { classes } = this.props;
    return (
      <Typography 
                style={{ color: "#222222", fontWeight: "bold"}} 
                className={`${classes.timelineContentDesc} scheduling_history_description ${classes.timelineDescription}  ${classes.marginDescription} `} 
                dangerouslySetInnerHTML={{ __html:item}} />
    )
  }
  renderBidAccepted = (bid_accepted: any) => {
    const { classes } = this.props;
    const title=replaceBidToQuote(bid_accepted.title);
    return <Grid container className={classes.displayDetailGrid}>
      <Grid container spacing={1} className={classes.displayDetailsMainBox}>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <CheckCircleOutlineRoundedIcon className={classes.milestoneTitleImage} />
          <Typography className={classes.timelineContentWorkReview}>{title}</Typography>
        </Grid>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <Avatar src={bid_accepted.profile_pic.url} className={classes.avatarStyle} />
          <Typography className={classes.timelineAvatarText}>{bid_accepted.user_name}</Typography>
        </Grid>
      </Grid>
      <style>
          {`
            .resTimelineContent > div {
              display: inline-flex !important;
              flex-wrap: wrap;
            }
            @media(max-width: 575px) {
              .resTimelineContent {
                display: block !important;
                font-size: 12px;
              }
            }
            @media(max-width: 500px) {
              .star-container {
                padding-right: 0 !important;
                padding-left: 0 !important;
              }
              .star-container svg {
                width: 25px !important;
              }
              fieldset, .star-ratings{
                width: 100% !important;
              }
              .star-ratings{
                display: flex !important;
                align-items: center;
                justify-content: space-between;
              }
              .resRatingText {
                width: 100%;
                justify-content: space-between;
                gap: 6px;
              }
            }
          `}
        </style>
      <Divider className={classes.divider}></Divider>
        <Grid container>
        <Typography 
                className={`${classes.timelineContentDesc} scheduling_history_description resTimelineContent ${classes.timelineDescription} `} 
                dangerouslySetInnerHTML={{ __html:getFreelancerMulitCurrencyHTML(bid_accepted.description,bid_accepted.currency_conversion)}} />
        </Grid> 
        <Box className={classes.timeWrapper}>
            <Typography className={classes.timeStyle} >
               <TimeOnly date={bid_accepted.created_at}/>
            </Typography>
          </Box>
    </Grid>


  }

  renderMilestoneCreated = (milestone_created: any) => {
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Grid container spacing={1} className={classes.displayDetailsMainBox}>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <img className={classes.milestoneTitleImage} src={milestoneCreatedIcon} />
          <Typography className={classes.timelineContentWorkReview}>{milestone_created.title}</Typography>
        </Grid>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <Avatar src={milestone_created.profile_pic.url} className={classes.avatarStyle} />
          <Typography className={classes.timelineAvatarText}>{milestone_created.user_name}</Typography>
        </Grid>
      </Grid>

      <Divider className={classes.divider}></Divider>
      <Grid container>
          <Typography className={`${classes.timelineContentDesc} scheduling_history_description resTimelineContent ${classes.timelineDescription}`} 
                 dangerouslySetInnerHTML={{ __html:milestone_created.description}} 
             ></Typography>
        </Grid>
          <Box className={classes.timeWrapper}>
            <Typography className={classes.timeStyle} >
               <TimeOnly date={milestone_created.created_at}/>
            </Typography>
          </Box>

    </Grid>

  }

  renderMileStoneFileUploaded = (file_uploaded: any) => {
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Grid container spacing={1} className={classes.displayDetailsMainBox}>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <img className={classes.milestoneTitleImage} src={chat} />
          <Typography className={classes.timelineContentWorkReview}>{file_uploaded.title}</Typography>
        </Grid>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <Avatar src={file_uploaded.profile_pic.url} className={classes.avatarStyle} />
          <Typography className={classes.timelineAvatarText}>{file_uploaded.user_name}</Typography>
        </Grid>
      </Grid>

      <Divider className={classes.uploadSubDivider}></Divider>
      <Grid container className={classes.downloadDetailsBox}>
        {
          file_uploaded.description[0].documents.map((doc:FileDetails) => (

            <Grid item xs="auto" sm={6} key={doc.blob_id}>
              <Box className={classes.downloadBox}>
                <Box className={classes.downloadInnerBox}>
                  <img className={classes.milestoneGridImage} src={workicon} />
                  <Grid container alignItems="center" alignContent="center">
                    <Grid item xs="auto">
                      <Typography className={classes.downloadFileName}>{this.convertFileName(doc.name)}</Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography data-testid="file_size"
                        className={classes.downloadFileSize}>
                        {this.convertFileSize(doc.size)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <CardMedia
                  data-testid="file_download"
                  component="img"
                  className={classes.downloadImgStyle}
                  image={download}
                  onClick={this.onDownloadFile.bind(this, doc.url, doc.name)}
                />
              </Box>
            </Grid>
          ))
        }
      </Grid>

      <Box className={classes.timeWrapper}>
        <Typography className={classes.timeStyle} >
          <TimeOnly date={file_uploaded.created_at}/>
        </Typography>
      </Box>
    </Grid>
  }

  renderMessageSent = (msg_sent: any) => {
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Grid container spacing={1} className={classes.displayDetailsMainBox}>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <img className={classes.milestoneTitleImage} src={chat} />
          <Typography className={classes.timelineContentWorkReview}>{msg_sent.title}</Typography>
        </Grid>
        <Grid item xs="auto"  className={classes.displayVerifyBox}>
          <Avatar src={msg_sent.profile_pic.url} className={classes.avatarStyle} />
          <Typography className={classes.timelineAvatarText}>{msg_sent.user_name}</Typography>
        </Grid>
      </Grid>

      <Divider className={classes.divider}></Divider>

      <Typography className={classes.timelineContentDesc} dangerouslySetInnerHTML={{ __html: msg_sent.description }} />
      <Box className={classes.timeWrapper}>
        <Typography className={classes.timeStyle} >
          <TimeOnly date={msg_sent.created_at}/>
        </Typography>
      </Box>
    </Grid>
  }

  renderMessageReceived = (msg_rec: any) => {
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Grid container spacing={1} className={classes.displayDetailsMainBox}>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <img className={classes.milestoneTitleImage} src={chat} />
          <Typography className={classes.timelineContentWorkReview}>{msg_rec.title}</Typography>
        </Grid>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <Avatar src={msg_rec.profile_pic.url} className={classes.avatarStyle} />
          <Typography className={classes.timelineAvatarText}>{msg_rec.user_name}</Typography>
        </Grid>
      </Grid>

      <Divider className={classes.divider}></Divider>

      <Typography className={classes.timelineContentDesc} dangerouslySetInnerHTML={{ __html: msg_rec.description }} />
      <Box className={classes.timeWrapper}>
        <Typography className={classes.timeStyle} >
          <TimeOnly date={msg_rec.created_at}/>
        </Typography>
      </Box>
    </Grid>

  }

  renderRequestedRevision = (request_revision: any) => {
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Grid container spacing={1} className={classes.displayDetailsMainBox}>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <img className={classes.milestoneTitleImage} src={chat} />
          <Typography className={classes.timelineContentWorkReview}>{request_revision.title}</Typography>
        </Grid>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <Avatar src={request_revision.profile_pic.url} className={classes.avatarStyle} />
          <Typography className={classes.timelineAvatarText}>{request_revision.user_name}</Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider}></Divider>
      <Typography className={classes.timelineContentDesc}
        dangerouslySetInnerHTML={{ __html: request_revision.description[0].revision_details }}
      />
      <Divider className={classes.uploadFileDivider}></Divider>
      <Grid container className={classes.downloadDetailsBox}>
        {
          request_revision.description[0].images && request_revision.description[0].images.map((file:FileDetails) => (

            <Grid item xs="auto" md={6} key={file.blob_id}>
              <Box className={classes.downloadBox}>
                <Box className={classes.downloadInnerBox}>
                  <img className={classes.milestoneGridImage} src={workicon} />
                  <Grid container alignItems="center" alignContent="center">
                    <Grid item xs="auto" style={{
                            whiteSpace: 'nowrap',
                            overflow:'hidden',
                            textOverflow: 'ellipsis',}}>
                      <Typography className={classes.downloadFileName}>{this.convertFileName(file.name)}</Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography className={classes.downloadFileSize}>{this.convertFileSize(file.size)}</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <CardMedia
                  component="img"
                  className={classes.downloadImgStyle}
                  image={download}
                  onClick={this.onDownloadFile.bind(this, file.url, file.name)}
                />
              </Box>
            </Grid>
          ))
        }
      </Grid>
      <Divider className={classes.divider}></Divider>
      <Typography className={classes.timelineContentDesc} dangerouslySetInnerHTML={{ __html: request_revision.description[0].deadline }} />
      <Box className={classes.timeWrapper}>
        <Typography className={classes.timeStyle} >
          <TimeOnly date={request_revision.created_at}/>
        </Typography>
      </Box>
    </Grid>

  }

  renderPaymentInitiated = (payment_initiated: any) => {
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Box className={classes.displayDetailsMainBox}>
        <Box className={classes.displayVerifyBox}>
          <CheckCircleOutlineRoundedIcon className={classes.milestoneTitleImage} />
          <Typography className={classes.timelineContentWorkReview}>{payment_initiated.title}</Typography>
        </Box>
        <Box className={classes.displayVerifyBox}>
          <Avatar src={payment_initiated.profile_pic.url} className={classes.avatarStyle} />
          <Typography className={classes.timelineAvatarText}>{payment_initiated.user_name}</Typography>
        </Box>
      </Box>

      <Divider className={classes.divider}></Divider>

      <Typography className={classes.timelineContentDesc} dangerouslySetInnerHTML={{ __html: payment_initiated.description }} />

      <Box className={classes.timeWrapper}>
        <Typography className={classes.timeStyle} >
          <TimeOnly date={payment_initiated.created_at}/>
        </Typography>
      </Box>
    </Grid>


  }

  renderPrjClosedAccepted = (prj_closure_accepted: any) => {
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Box className={classes.displayDetailsMainBox}>
        <Box className={classes.displayVerifyBox}>
          <CheckCircleOutlineRoundedIcon className={classes.milestoneTitleImage} />
          <Typography className={classes.timelineContentWorkReview}>{prj_closure_accepted.title}</Typography>
        </Box>
        <Box className={classes.displayVerifyBox}>
          <Avatar src={prj_closure_accepted.profile_pic.url} className={classes.avatarStyle} />
          <Typography className={classes.timelineAvatarText}>{prj_closure_accepted.user_name}</Typography>
        </Box>
      </Box>

      <Divider className={classes.divider}></Divider>

      <Typography className={classes.timelineContentDesc} dangerouslySetInnerHTML={{ __html: prj_closure_accepted.description }} />

      <Box className={classes.timeWrapper}>
        <Typography className={classes.timeStyle} >
          <TimeOnly date={prj_closure_accepted.created_at}/>
        </Typography>
      </Box>
    </Grid>


  }
  renderPrjClosedRejected = (prj_closure_rejected: any) => {
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Box className={classes.displayDetailsMainBox}>
        <Box className={classes.displayVerifyBox}>
          <CheckCircleOutlineRoundedIcon className={classes.milestoneTitleImage} />
          <Typography className={classes.timelineContentWorkReview}>{prj_closure_rejected.title}</Typography>
        </Box>
        <Box className={classes.displayVerifyBox}>
          <Avatar src={prj_closure_rejected.profile_pic.url} className={classes.avatarStyle} />
          <Typography className={classes.timelineAvatarText}>{prj_closure_rejected.user_name}</Typography>
        </Box>
      </Box>

      <Divider className={classes.divider}></Divider>

      <Typography className={classes.timelineContentDesc} dangerouslySetInnerHTML={{ __html: prj_closure_rejected.description }} />

      <Box className={classes.timeWrapper}>
        <Typography className={classes.timeStyle} >
          <TimeOnly date={prj_closure_rejected.created_at}/>
        </Typography>
      </Box>
    </Grid>


  }
  renderPrjOnHold = (prj_on_hold: any) => {
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Grid container spacing={1} className={classes.displayDetailsMainBox}>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <CheckCircleOutlineRoundedIcon className={classes.milestoneTitleImage} />
          <Typography className={classes.timelineContentWorkReview}>{prj_on_hold.title}</Typography>
        </Grid>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <Avatar src={prj_on_hold.profile_pic.url} className={classes.avatarStyle} />
          <Typography className={classes.timelineAvatarText}>{prj_on_hold.user_name}</Typography>
        </Grid>
      </Grid>

      <Divider className={classes.divider}></Divider>
      <Box className={classes.approvedInfoBox}>
        <InfoOutlinedIcon className={classes.approvedIconStyle} />
        {
          this.renderReasonGrid(prj_on_hold.description)
        }
      </Box>
      <Box className={classes.timeWrapper}>
        <Typography className={classes.timeStyle} >
          <TimeOnly date={prj_on_hold.created_at}/>
        </Typography>
      </Box>
    </Grid>


  }

  renderProjectFinished = (prj_finished: {is_rated:boolean}) => {
    const { classes } = this.props;
    return (
         <Grid container className={classes.displayDetailGrid}>
          <Box className={classes.displayDetailsMainBox}>
            <Box className={classes.displayVerifyBox}>
              <CheckCircleIcon className={classes.milestoneTitleImage} />
              <Typography className={classes.timelineContentWorkReview}>Project Finished</Typography>
            </Box>

          </Box>

          <Divider className={classes.divider}></Divider>

          <Button type="submit" 
          variant="contained"
          disableElevation
            onClick={this.handleOpen}
            className={classes.shareYourFeedBackReview}
            style={{
              fontWeight: "bold",
              textTransform: "none"
            }}
            disabled={prj_finished.is_rated}
          >
            {configJSON.shareYourFeedBackReview}
          </Button>
        </Grid>
    )

  }
      
  renderPrjClosedRequested = (prj_closure_requested: HistoryDetails & { project_clouser_status?: string; flag_title?: string }) => {
    const{classes}=this.props;
          return  <Grid container className={classes.displayDetailGrid}>
                <Box className={classes.displayDetailsMainBox}>
                    <Box className={classes.displayVerifyBox}>
                        <img src={projectCloseIcon} alt="projectCloseIcon" className={classes.milestoneTitleImage}/>
                        <Typography className={classes.timelineContentWorkReview}>{prj_closure_requested.title}
                        </Typography>
                    </Box>
                    <Box className={classes.displayVerifyBox}>
                    <Avatar src={prj_closure_requested.profile_pic?.url}
                    className={classes.avatarStyle}/>
                    <Typography className={classes.timelineAvatarText}> {prj_closure_requested.user_name}
                    </Typography>
                    </Box>
                </Box>  
        
                    <Divider className={classes.divider}></Divider>
                    <Typography className={classes.timelineContentDesc}  dangerouslySetInnerHTML={{ __html: prj_closure_requested.description }}/>
   
                     {/* ------------- Accept and Reject buttons ---------------------------------------- */}
                     {prj_closure_requested.project_clouser_status === 'pending' &&
                     prj_closure_requested.flag_title !== undefined &&
                     this.renderCommonAcceptRejectBtn(this.submitClosureOption, prj_closure_requested.flag_title)}
                     {/* -------------------------------------------------------------------------------- */}
                    <Box className={classes.timeWrapper}>
                        <Typography className={classes.timeStyle} >
                           <TimeOnly date={prj_closure_requested.created_at}/>
                        </Typography>
                    </Box>                                   
                </Grid>
         }

         renderDisputeRaised = (dispute_raised:DisputeRaisedDetails & { dispute_request_status?: string; flag_title?: string })=>{
          const{classes}=this.props;
          return  <Grid container className={classes.displayDetailGrid}>
                <Box className={classes.displayDetailsMainBox}>
                    <Box className={classes.displayVerifyBox}>
                        <img src={disputeIcon} alt="disputeIcon" className={classes.milestoneTitleImage}/>
                        <Typography className={classes.timelineContentWorkReview}>{dispute_raised.title}
                        </Typography>
                    </Box>
                    <Box className={classes.displayVerifyBox}>
                    <Avatar src={dispute_raised.profile_pic?.url}
                    className={classes.avatarStyle}/>
                    <Typography className={classes.timelineAvatarText}> {dispute_raised.user_name}
                    </Typography>
                    </Box>
                </Box>  
                     {/* -------------------reason description-------------------------------------- */}
                    <Divider className={classes.uploadSubDivider}></Divider>
                    <Typography className={classes.disputeRaisedStyle}  dangerouslySetInnerHTML={{ __html: dispute_raised.description[0].description_html }}/>
                     {/* -------------------display uploaded files-------------------------------------- */}
                     {
                              dispute_raised.description[0]?.images_html?.length > 0 &&
                               <Divider className={classes.uploadSubDivider}></Divider>
                     }
                      <Grid container className={classes.downloadDetailsBox}>
                        {
                          dispute_raised.description[0]?.images_html?.map((doc_raised:FileDetails) => (

                            <Grid item xs={12} md={6} key={doc_raised.blob_id}>
                              <Box className={classes.downloadBox}>
                                <Box className={classes.downloadInnerBox}>
                                  <img className={classes.milestoneGridImage} src={workicon} alt="dispute_raised_image" />
                                  <Typography className={classes.downloadFileName}>{this.convertFileName(doc_raised.name)}</Typography>
                                  <Typography data-testid="file_size"
                                    className={classes.downloadFileSize}>
                                    {this.convertFileSize(doc_raised.size)}
                                  </Typography>
                                </Box>
                                <CardMedia
                                  data-testid="file_download"
                                  component="img"
                                  className={classes.downloadImgStyle}
                                  onClick={this.onDownloadFile.bind(this, doc_raised.url, doc_raised.name)}
                                  image={download}
                                />
                              </Box>
                            </Grid>
                          ))
                        }
                      </Grid>
                     {/* -------------------category and amount-------------------------------------- */}
                    <Divider className={classes.subDivider}></Divider>
                    <Typography className={classes.timelineContentDesc}  dangerouslySetInnerHTML={{ __html: dispute_raised.description[0].html }}/>
                     {/* ------------- Accept and Reject buttons ---------------------------------------- */}
                      {
                      (dispute_raised.dispute_request_status === configJSON.raiseFlagTxt || dispute_raised.dispute_request_status === null) &&
                       dispute_raised.flag_title !== undefined &&
                      this.renderCommonAcceptRejectBtn(this.submitDisputeOption, dispute_raised.flag_title,
                      dispute_raised?.description[0]?.raise_dispute_id
                      )}
                     {/* -------------------------------------------------------------------------------- */}
                    <Box className={classes.disputeFileTimeWrapper}>
                        <Typography className={classes.timeStyle} >
                          <TimeOnly date={dispute_raised.created_at}/>
                         </Typography>
                    </Box>                                   
                </Grid>
         }

         renderDisputeAccepted = (dispute_accepted: HistoryDetails) => {
          const { classes } = this.props;
          return <Grid container className={classes.displayDetailGrid}>
            <Box className={classes.displayDetailsMainBox}>
              <Box className={classes.displayVerifyBox}>
                <CheckCircleOutlineRoundedIcon className={classes.milestoneTitleImage} />
                <Typography className={classes.timelineContentWorkReview}>{dispute_accepted.title}</Typography>
              </Box>
              <Box className={classes.displayVerifyBox}>
                <Avatar src={dispute_accepted.profile_pic?.url} className={classes.avatarStyle} />
                <Typography className={classes.timelineAvatarText}>{dispute_accepted.user_name}</Typography>
              </Box>
            </Box>
      
            <Divider className={classes.divider}></Divider>
      
            <Typography className={classes.timelineContentDesc} dangerouslySetInnerHTML={{ __html: dispute_accepted.description }} />
      
            <Box className={classes.timeWrapper}>
              <Typography className={classes.timeStyle} >
                 <TimeOnly date={dispute_accepted.created_at}/>
              </Typography>
            </Box>
          </Grid>
      
      
        }

        renderDisputeRejected = (dispute_rejected: DisputeDetails & { project_closure_status?: string; flag_title?: string }) => {
          const { classes } = this.props;
          return <Grid container className={classes.displayDetailGrid}>
            <Box className={classes.displayDetailsMainBox}>
              <Box className={classes.displayVerifyBox}>
                <CheckCircleOutlineRoundedIcon className={classes.milestoneTitleImage} />
                <Typography className={classes.timelineContentWorkReview}>{dispute_rejected.title}</Typography>
              </Box>
              <Box className={classes.displayVerifyBox}>
                <Avatar src={dispute_rejected.profile_pic?.url} className={classes.avatarStyle} />
                <Typography className={classes.timelineAvatarText}>{dispute_rejected.user_name}</Typography>
              </Box>
            </Box>
                                                                                                      
               {/* -------------------description reason -------------------------------------- */}
                  <Divider className={classes.uploadSubDivider}></Divider>
                  <Typography className={classes.timelineDisputeDesc} dangerouslySetInnerHTML={{ __html: dispute_rejected?.description[0]?.html }} />
                
                   {/* -------------------display uploaded files-------------------------------------- */}
                   {
                        (dispute_rejected.description[0]?.image && dispute_rejected.description[0]?.image.length > 0) &&
                        <Divider className={classes.uploadFileDivider}></Divider>
                   }
                              <Grid container className={classes.downloadDetailsBox}>
                                {
                                  dispute_rejected.description[0]?.image && dispute_rejected.description[0]?.image.map((doc_reject:FileDetails) => (

                                    <Grid item xs={12} md={6} key={doc_reject.blob_id}>
                                      <Box className={classes.downloadBox}>
                                        <Box className={classes.downloadInnerBox}>
                                          <img 
                                          alt="dispute_image" 
                                          className={classes.milestoneGridImage} 
                                          src={workicon} 
                                          />
                                          <Typography
                                           className={classes.downloadFileName}>
                                            {this.convertFileName(doc_reject.name)}
                                           </Typography>
                                          <Typography data-testid="file_size"
                                            className={classes.downloadFileSize}>
                                            {this.convertFileSize(doc_reject.size)}
                                          </Typography>
                                        </Box>
                                        <CardMedia
                                          data-testid="file_download"
                                          component="img"
                                          className={classes.downloadImgStyle}
                                          onClick={this.onDownloadFile.bind(this, doc_reject.url, doc_reject.name)}
                                          image={download}
                                        />
                                      </Box>
                                    </Grid>
                                  ))
                                }
                              </Grid>
                  
                   {/* -------------------------- category and amount------------------------ */}
            
                   <Divider className={classes.disputeSubDivider}></Divider>
                
                  <Typography className={classes.timelineDisputeDesc} dangerouslySetInnerHTML={{ __html: dispute_rejected.description[0].reason_html }} />
          
            <Box className={classes.disputeFileTimeWrapper}>
              <Typography className={classes.timeStyle} >
                <TimeOnly date={dispute_rejected.created_at}/>
              </Typography>
            </Box>
          </Grid>
     }

     renderCommonAcceptRejectBtn = (handleOptionSubmit: any, flagTitleText: string, disputedId?:number) => {
      const{classes}=this.props;
           return <>
                {!this.state.hideBtn && <>
                    <Divider className={classes.btnDivider}></Divider>
                   { flagTitleText==configJSON.closureRequestedTxt &&  <>
                      <Grid container spacing={1}>
                          <Grid item xs="auto">
                            <Button 
                            data-testid= "acceptBtn"
                            onClick={this.handleAcceptButton} 
                            className={`${classes.projectCloseButton} ${classes.projectAcceptBtnColor}`}
                            >
                            {this.state.closureLoader?<CircularProgress data-testid='closure_loader' size={24} />:configJSON.acceptBtnTxt}  
                            </Button>
                          </Grid>
                          <Grid item xs="auto">
                            <Button 
                            onClick={this.handleRejectButton} 
                            className={`${classes.projectCloseButton} ${classes.projectRejectBtnColor}`}
                            data-testid= "rejectBtn"
                            >
                              {configJSON.rejectBtnTxt}
                            </Button>
                          </Grid>
                      </Grid>

                    </>
                   }

                  { flagTitleText==configJSON.disputeRaisedTxt && <>
                    <Grid container spacing={1}>
                      <Grid item xs="auto">
                        <Button 
                        data-testid= {"user"+flagTitleText}
                        onClick={this.handleAcceptDisputeButton.bind(this, disputedId)} 
                        className={`${classes.projectCloseButton} ${classes.projectAcceptBtnColor}`}
                        >
                        {this.state.closureLoader?<CircularProgress data-testid={'closure_loader'+flagTitleText} size={24} />:configJSON.acceptBtnTxt}  
                        </Button>
                      </Grid>
                      <Grid item xs="auto">
                        <Button 
                        onClick={this.handleRejectButton} 
                        className={`${classes.projectCloseButton} ${classes.projectRejectBtnColor}`}
                        data-testid= {"rejectBtn"}
                        >
                          {configJSON.rejectBtnTxt}
                        </Button>
                      </Grid>
                    </Grid>

                    </>
                   }

                    </>
                    }
                    {
                      this.state.openRejectBox && 
                      <Grid container className={classes.rejectionDetailsContainer}>
                    
                      <Grid item xs={12} >
                      <FormControl component="fieldset" className={classes.FormControlStyle} >
                      <FormLabel className={classes.rejectionFormTitleStyle}> {configJSON.RejectionHeaderTxt} </FormLabel>
                      
                      <RadioGroup aria-label="RejectionOptions" name="RejectionOptions" 
                       data-testid= "reject_option"
                       value={this.state.rejectionValue}
                       onChange={this.handleRejectionOptionChange}
                       >
                        <FormControlLabel value={configJSON.rejectOptionValue1} control={<StyledRadioButton />} label={<Typography className={classes.rejectOptionLabelStyle}>{configJSON.rejectOptionTxt1}</Typography>}/>
                        <FormControlLabel value={configJSON.rejectOptionValue2} control={<StyledRadioButton />} label={<Typography className={classes.rejectOptionLabelStyle}>{configJSON.rejectOptionTxt2}</Typography>}/>
                        <FormControlLabel value={configJSON.rejectOptionValue3}control={<StyledRadioButton />} label={<Typography className={classes.rejectOptionLabelStyle}>{configJSON.rejectOptionTxt3}</Typography>} />

                    </RadioGroup>
                    </FormControl>
                    { this.state.rejectionValue == configJSON.rejectOptionValue3 &&  <TextareaAutosize minRows={3}
                              value={this.state.otherRejection}
                               onChange={this.handleOtherRejectionChange}
                               data-testid='rejection_msgbox'
                               className={classes.rejectionTextAreaStyle}
                               placeholder={configJSON.textareaPlaceholder}></TextareaAutosize>
                     }
                     {
                      flagTitleText==configJSON.disputeRaisedTxt && <>
                      <Typography className={classes.optionalTxtStyle}>{configJSON.optionTxt}</Typography>
                      <Box className={classes.uploadDisputeStyle}>
                          <Box className={classes.uploadDisputeTextStyle}>{configJSON.labelUpload}
                          <Typography className={classes.uploadDisputeMaxTextStyle}> 
                          {configJSON.labelMaxUpload}
                          </Typography>
                      </Box>                
                      <input type="file" 
                      multiple 
                      disabled={this.state.rejectedLoader}
                      onChange={this.handleDisputeFiles} 
                      className={classes.uploadDisputeInputStyle}
                      data-testid = "disputeUploadFiles"
                      value=""
                      />
                      </Box> 

                      <Grid container className={classes.downloadDetailsBox}>
                        {
                        this.state.uploadDisputeFiles.map((dispute_doc: any, index:number) => (

                            <Grid item xs={12} md={6} key={dispute_doc.blob_id}>
                              <Box className={classes.downloadBox}>
                                <Box className={classes.downloadInnerBox}>
                                  <img alt="dispute file icon" className={classes.milestoneGridImage} src={workicon} />
                                  <Typography className={classes.downloadFileName}>{this.convertFileName(dispute_doc.name)}</Typography>
                                  <Typography data-testid="file_size"
                                    className={classes.downloadFileSize}>
                                    {this.convertFileSize(dispute_doc.size)}
                                  </Typography>
                                </Box>
                                    <CardMedia
                                    component="img"
                                    data-testId="deleteIcon"
                                    className={classes.deleteFilesIcon}
                                    image={deleteFileIcon}
                                    onClick={ this.deleteDisputedFiles.bind(this,index) }
                                />
                              </Box>
                            </Grid>
                          ))
                        }
                      </Grid>
                      </>
                      
                     }
               </Grid>
               <Box className={`${classes.flexClassStyle} ${classes.flexClassAtributesStyle}`}>
                    <Button 
                    className={`${classes.projectCloseButton} ${classes.projectCancelBtnColor}`}
                    onClick={this.handleOptionCancel}
                    data-testid='cancel_button'
                    disabled={this.state.rejectedLoader}
                    >
                      {configJSON.cancelButton}
                    </Button>
                    <Button 
                        className={`${classes.projectCloseButton} ${classes.projectSubmitBtnColor}`}
                        onClick={handleOptionSubmit.bind (this, disputedId )}
                        data-testid={'submit_button'}
                        disabled={this.state.rejectedLoader}
                    >
                      {this.state.rejectedLoader?<CircularProgress  data-testid='loader' size={24} />:configJSON.submitButton}  
                   </Button>
                    </Box>
              </Grid> 
           }
      </>
     }

     renderRefundRequested = (prj_refund_requested: RefundDetails & { refund_status?: string; flag_title?: string;cooling_period_expired:boolean }) => {
      const{classes}=this.props;
            return  <Grid container className={classes.displayDetailGrid}>
                  <Grid container spacing={1} className={classes.displayDetailsMainBox}>
                      <Grid item xs="auto" className={classes.displayVerifyBox}>
                          <img src={projectCloseIcon} alt="projectRefundIcon" className={classes.milestoneTitleImage}/>
                          
                          <Typography className={classes.timelineContentWorkReview}>
                            {`${prj_refund_requested.milestone_title} - ${prj_refund_requested.title} `}
                          </Typography>
                      </Grid>
                      <Grid item xs="auto" className={classes.displayVerifyBox}>
                      <Avatar src={prj_refund_requested.profile_pic?.url}
                      className={classes.avatarStyle}/>
                      <Typography className={classes.timelineAvatarText}> {prj_refund_requested.user_name}
                      </Typography>
                      </Grid>
                  </Grid>  
          
                      <Divider className={classes.divider}></Divider>
                      { this.renderReasonGrid(prj_refund_requested?.description.html) }
     
                        {/* ------------- Refund Accept and Reject buttons ---------------------------------------- */}
                        {   prj_refund_requested.refund_status === configJSON.refundFlagTxt &&
                             this.refundAcceptRejectBtn(prj_refund_requested.refund_id,prj_refund_requested.cooling_period_expired)}
                        {/* -------------------------------------------------------------------------------- */}
                          
                            <Box className={classes.timeWrapper}>
                                <Typography className={classes.timeStyle} >
                                   <TimeOnly date={prj_refund_requested.created_at}/>
                                </Typography>
                             </Box>                                   
                       </Grid>
             }

   refundAcceptRejectBtn = (refundId:number,isPeriodExpired:boolean) => {
          const{classes}=this.props;
                 return <>
                      {!this.state.hideBtn && <>
                          <Divider 
                            className={classes.btnDivider}>
                          </Divider>

                          <Grid container spacing={1} className={this.refundRejectStatusId ==  refundId.toString() ? classes.displayNoneStyle:classes.flexClassStyle}>
                         <Grid item xs={12} sm="auto" id="statusBtn">
                           <Button 
                           data-testid= "acceptRefundBtn"
                           onClick={this.handleAcceptRefundButton.bind(this,refundId)} 
                           className={`${classes.projectCloseButton} ${classes.projectAcceptBtnColor}`}
                           disabled={(this.state.closureLoader && (this.refundStatusId === refundId.toString()))
                          || (this.refundRejectStatusId === refundId.toString()) || isPeriodExpired
                          }
                           >
                          {(this.state.closureLoader && (this.refundStatusId === refundId.toString())) ?
                            <CircularProgress 
                            data-testid={'acceptRefundLoader'}
                             size={24} />
                          :
                          configJSON.acceptBtnTxt}  
                          </Button>
                          </Grid>
                          <Grid item xs={12} sm="auto">
                            <Box style={{
                              display:"flex",
                              alignItems:"center",
                              gap:"10px"
                            }}>
                              <Button 
                              onClick={this.handleRejectRefundButton.bind(this,refundId)} 
                              className={`${classes.projectCloseButton} ${classes.projectRejectBtnColor}`}
                              data-testid= "rejectRefundBtn"
                              disabled={(this.refundStatusId === refundId.toString())
                                || (this.refundRejectStatusId === refundId.toString()) || isPeriodExpired
                                }
                              >
                                {configJSON.rejectBtnTxt}
                              </Button>
                              {
                                isPeriodExpired && (
                                <Tooltip  enterTouchDelay={0} leaveTouchDelay={3000} title={`Cooling period expired`} arrow placement='top'>
                                  <InfoIcon style={{
                                    height:'16px',
                                    width:'16px',
                                    color:'#000'
                                  }}/>
                                </Tooltip>
                                )
                              }
                             
                          </Box>
                          </Grid>
                          </Grid>
                          </>
                          }
                          {
                            this.state.openRejectBox && (this.refundRejectStatusId === refundId.toString()) &&
                            <Grid container className={classes.rejectionDetailsContainer}>
                          
                            <Grid item xs={12} >
                            <FormControl component="fieldset" className={classes.FormControlStyle} >
                            <FormLabel className={classes.rejectionFormTitleStyle}> {configJSON.RejectionHeaderTxt} </FormLabel>
                            
                            <RadioGroup 
                             name="RejectionOptions" 
                             data-testid= "rejectRefundOption"
                             onChange={this.handleRejectionOptionChange}
                             value={this.state.rejectionValue}
                             >
                              <FormControlLabel value={configJSON.rejectRefundOptionValue1} 
                              control={<StyledRadioButton />} 
                              label={<Typography className={classes.rejectOptionLabelStyle}>{configJSON.rejectRefundOptionTxt1}</Typography>}
                              />
                              <FormControlLabel value={configJSON.rejectRefundOptionValue2} 
                              control={<StyledRadioButton />} 
                              label={<Typography className={classes.rejectOptionLabelStyle}>{configJSON.rejectRefundOptionTxt2}</Typography>}
                              />
                              <FormControlLabel value={configJSON.rejectRefundOptionValue3}
                              control={<StyledRadioButton />} 
                              label={<Typography className={classes.rejectOptionLabelStyle}>{configJSON.rejectRefundOptionTxt3}</Typography>} 
                              />
      
                          </RadioGroup>
                          </FormControl>
                          { this.state.rejectionValue === configJSON.rejectOptionValue3 &&  <TextareaAutosize minRows={3}
                                     data-testid='rejectionOtherMsgBox'
                                     className={classes.rejectionTextAreaStyle}
                                     placeholder={configJSON.textareaPlaceholder}
                                     value={this.state.otherRejection}
                                     onChange={this.handleOtherRejectionChange}></TextareaAutosize>
                           }

                     </Grid>
                     <Box className={`${classes.flexClassStyle} ${classes.flexClassAtributesStyle}`}>
                          <Button 
                          className={`${classes.projectCloseButton} ${classes.projectCancelBtnColor}`}
                          onClick={this.handleOptionCancel}
                          data-testid='cancelRefundButton'
                          disabled={this.state.rejectedLoader}
                          >
                            {configJSON.cancelButton}
                          </Button>
                          <Button 
                              className={`${classes.projectCloseButton} ${classes.projectSubmitBtnColor}`}
                              onClick={this.onSubmitRejectRefundButton.bind(this,refundId)} 
                              data-testid={'submitRefundButton'}
                              disabled={this.state.rejectedLoader && (this.refundRejectStatusId === refundId.toString())}
                          >
                            {
                            this.state.rejectedLoader && (this.refundRejectStatusId === refundId.toString())?
                            <CircularProgress  data-testid='rejectRefundLoader' size={24} />
                            :configJSON.submitButton
                            }  
                         </Button>
                          </Box>
                    </Grid> 
                 }
            </>
   }
  
   renderRefundAccepted = (prj_refund_accepted: RefundDetails) => {
    const { classes } = this.props;
    const description=getFreelancerMulitCurrencyHTML(prj_refund_accepted.description.html_refund_amount,prj_refund_accepted?.currency_conversion);
    return <Grid container className={classes.displayDetailGrid}>
      <Grid container spacing={1} className={classes.displayDetailsMainBox}>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <CheckCircleOutlineRoundedIcon className={classes.milestoneTitleImage} />
          <Typography className={classes.timelineContentWorkReview}>
            {`${prj_refund_accepted.milestone_title} - ${prj_refund_accepted.title}`}
            </Typography>
        </Grid>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <Avatar src={prj_refund_accepted.profile_pic?.url} className={classes.avatarStyle} />
          <Typography className={classes.timelineAvatarText}>{prj_refund_accepted.user_name}</Typography>
        </Grid>
      </Grid>

      <Divider className={classes.divider}></Divider>

      <Typography 
        style={{display:"flex",gap:"5px"}}
        className={`${classes.timelineContentDesc} scheduling_history_description`} 
        dangerouslySetInnerHTML={{ __html: description }} 

        />

      <Box className={classes.timeWrapper}>
        <Typography className={classes.timeStyle} >
            <TimeOnly date={prj_refund_accepted.created_at}/>
        </Typography>
      </Box>
    </Grid>
  }

  renderRefundRejected = (prj_refund_rejected: RefundDetails) => {
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Grid container spacing={1} className={classes.displayDetailsMainBox}>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <CheckCircleOutlineRoundedIcon className={classes.milestoneTitleImage} />
          <Typography className={classes.timelineContentWorkReview}>
            {`${prj_refund_rejected.milestone_title} - ${prj_refund_rejected.title}`}
            </Typography>
        </Grid>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <Avatar src={prj_refund_rejected.profile_pic?.url} className={classes.avatarStyle} />
          <Typography className={classes.timelineAvatarText}>{prj_refund_rejected.user_name}</Typography>
        </Grid>
      </Grid>

      <Divider className={classes.divider}></Divider>
      <Grid container>
        <Grid item xs="auto">
          <Typography className={`${classes.timelineContentDesc} scheduling_history_description resTimelineContent ${classes.timelineDescription} ${classes.marginDescription}`} 
            dangerouslySetInnerHTML={{ __html:prj_refund_rejected.description.html}}
          ></Typography>
        </Grid>
      </Grid>

      <Box className={classes.timeWrapper}>
        <Typography className={classes.timeStyle} >
          <TimeOnly date={prj_refund_rejected.created_at}/>
        </Typography>
      </Box>
    </Grid>
  }

  renderMilestoneDisputeRaised = (milestone_dispute_raised: MilestoneDisputeDetails & { dispute_status?: string; flag_title?: string; }) => {
    let description=getFreelancerMulitCurrencyHTML(milestone_dispute_raised.description.html_dispute_amount,milestone_dispute_raised?.currency_conversion);
    // description="DISPUTE RAISED HTML"
    const{classes}=this.props;
          return  <Grid container className={classes.displayDetailGrid}>
                <Grid container spacing={1} className={classes.displayDetailsMainBox}>
                  <Grid item xs="auto" className={classes.displayVerifyBox}>
                      <img src={projectCloseIcon} alt="milestoneDisputeRaised" className={classes.milestoneTitleImage}/>
                      
                      <Typography className={classes.timelineContentWorkReview}>
                        {`${milestone_dispute_raised.milestone_title} - ${milestone_dispute_raised.title}`}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto" className={classes.displayVerifyBox}>
                      <Avatar src={milestone_dispute_raised.profile_pic?.url}
                      className={classes.avatarStyle}/>
                      <Typography className={classes.timelineAvatarText}> {milestone_dispute_raised.user_name}
                      </Typography>
                    </Grid>
                  </Grid>  
        
                    <Divider className={classes.divider}></Divider>
                    <Typography 
                      className={`${classes.descRejectStyle}`}  
                      dangerouslySetInnerHTML={{ __html:milestone_dispute_raised.description.html}}/>
                     {/* -------------------milestone level dispute display uploaded files-------------------------------------- */}
                     {
                       milestone_dispute_raised.description?.dispute_documents?.length > 0 &&
                        <Divider className={classes.uploadFileDivider}></Divider>
                     }
                      <Grid container className={classes.downloadDetailsBox}>
                        {
                          milestone_dispute_raised.description?.dispute_documents?.map((dispute_raised:FileDetails) => (

                            <Grid item xs={12} md={6} key={dispute_raised.blob_id}>
                              <Box className={classes.downloadBox}>
                                <Box className={classes.downloadInnerBox}>
                                  <img className={classes.milestoneGridImage} src={workicon} alt="dispute_raised_image" />
                                  <Typography className={classes.downloadFileName}>{this.convertFileName(dispute_raised.name)}</Typography>
                                  <Typography data-testid="file_size"
                                    className={classes.downloadFileSize}>
                                    {this.convertFileSize(dispute_raised.size)}
                                  </Typography>
                                </Box>
                                <CardMedia
                                  data-testid="file_download"
                                  component="img"
                                  className={classes.downloadImgStyle}
                                  onClick={this.onDownloadFile.bind(this, dispute_raised.url, dispute_raised.name)}
                                  image={download}
                                />
                              </Box>
                            </Grid>
                          ))
                        }
                      </Grid>
                     {/* -------------------milestone level dispute category and amount-------------------------------------- */}
 
                    {
                       milestone_dispute_raised.description?.dispute_documents?.length > 0 
                       ?   <Divider className={classes.subDivider}></Divider>
                       :  <Divider className={classes.rejectSubDivider}></Divider>
                    }

                    <Typography 
                      style={{display:"flex",gap:"5px"}}
                      className={`${classes.timelineContentDesc} scheduling_history_description`} 
                      dangerouslySetInnerHTML={{ __html: description }}/>   
                      {/* ------------- Milestone level dispute Accept and Reject buttons ---------------------------------------- */}
                      {   milestone_dispute_raised.description.dispute_request === configJSON.disputeRequestFlag &&
                             this.disputeAcceptRejectBtn(milestone_dispute_raised.description.milestone_id)}
                      {/* -------------------------------------------------------------------------------- */}
                        
                          <Box className={classes.timeWrapper}>
                              <Typography className={classes.timeStyle} >
                                <TimeOnly date={milestone_dispute_raised.created_at}/>
                              </Typography>
                           </Box>                                   
                     </Grid>
   }

   disputeAcceptRejectBtn = (milestoneDisputeId:number) => {
    const{classes}=this.props;
   
           return <>
                {!this.state.hideBtn && <>
                    <Divider 
                      className={classes.btnDivider}>
                    </Divider>

                    <Grid id="statusBtn" container spacing={1}  className={this.rejectMilestoneDisputeId ==  milestoneDisputeId.toString() ? classes.displayNoneStyle:classes.flexClassStyle}>
                      <Grid item xs="auto">

                     <Button 
                     data-testid= "acceptMilestoneDisputeBtn"
                     onClick={this.handleAcceptMilestoneDispute.bind(this,milestoneDisputeId)} 
                     className={`${classes.projectCloseButton} ${classes.projectAcceptBtnColor}`}
                     disabled={(this.state.closureLoader && (this.acceptMilestoneDisputeId === milestoneDisputeId.toString()))
                    || (this.rejectMilestoneDisputeId === milestoneDisputeId.toString())
                    }
                     >
               
                    {(this.state.closureLoader && (this.acceptMilestoneDisputeId === milestoneDisputeId.toString())) ?
                      <CircularProgress 
                      data-testid={'acceptMilestoneDisputeLoader'}
                       size={24} />
                    :
                    configJSON.acceptBtnTxt}  
                    </Button>
                      </Grid>
                      <Grid item xs="auto">
                        <Button 
                        onClick={this.handleRejectMilestoneDispute.bind(this,milestoneDisputeId)} 
                        className={`${classes.projectCloseButton} ${classes.projectRejectBtnColor}`}
                        data-testid= "rejectMilestoneDisputeBtn"
                        disabled={(this.acceptMilestoneDisputeId === milestoneDisputeId.toString())
                          || (this.rejectMilestoneDisputeId === milestoneDisputeId.toString())
                          }
                        >
                          {configJSON.rejectBtnTxt}
                        </Button>
                      </Grid>
                    </Grid>
                    </>
                    }
                    {
                      this.state.openRejectBox && (this.rejectMilestoneDisputeId === milestoneDisputeId.toString()) &&
                      <Grid container className={classes.rejectionDetailsContainer}>
                    
                      <Grid item xs={12} >
                      <FormControl component="fieldset" className={classes.FormControlStyle} >
                      <FormLabel className={classes.rejectionFormTitleStyle}> {configJSON.RejectionHeaderTxt} </FormLabel>
                      
                      <RadioGroup 
                       name="RejectionOptions" 
                       onChange={this.handleRejectionOptionChange}
                       value={this.state.rejectionValue}
                       data-testid= "rejectMilestoneDisputeOption"
                       >
                        <FormControlLabel value={configJSON.rejectDisputeMilestoneOptionValue1} 
                        control={<StyledRadioButton />} 
                        label={<Typography className={classes.rejectOptionLabelStyle}>{configJSON.rejectDisputeMilestoneOptionTxt1}</Typography>}
                        />
                        <FormControlLabel value={configJSON.rejectDisputeMilestoneOptionValue2} 
                        control={<StyledRadioButton />} 
                        label={<Typography className={classes.rejectOptionLabelStyle}>{configJSON.rejectDisputeMilestoneOptionTxt2}</Typography>}
                        />
                        <FormControlLabel value={configJSON.rejectDisputeMilestoneOptionValue3}
                        control={<StyledRadioButton />} 
                        label={<Typography className={classes.rejectOptionLabelStyle}>{configJSON.rejectDisputeMilestoneOptionTxt3}</Typography>} 
                        />

                    </RadioGroup>
                    </FormControl>
                    { this.state.rejectionValue === configJSON.rejectDisputeMilestoneOptionValue3 &&  <TextareaAutosize minRows={3}
                              value={this.state.otherRejection}
                               placeholder={configJSON.textareaPlaceholder}
                               data-testid='rejectOtherMsgBox'
                               className={classes.rejectionTextAreaStyle}
                               onChange={this.handleOtherRejectionChange}></TextareaAutosize>
                     }
                     {/* -----------------------------------Upload Reject files----------------------------------------------- */}
                     <>
                      <Typography className={classes.optionalTxtStyle}>
                        {configJSON.optionTxt}
                       </Typography>
                      <Box className={classes.uploadDisputeStyle}>

                      <Box className={classes.uploadDisputeTextStyle}>{configJSON.labelUpload}
                      <Typography className={classes.uploadDisputeMaxTextStyle}> {configJSON.labelMaxUpload}</Typography>
                 
                      </Box>                
                      <input type="file" 
                      multiple 
                      className={classes.uploadDisputeInputStyle}
                      onChange={this.handleDisputeFiles} 
                      value=""
                      data-testid = "disputeUploadFiles"
                      disabled={this.state.rejectedLoader}
                      />
                      </Box> 

                      <Grid container className={classes.downloadDetailsBox}>
                        {
                        this.state.uploadDisputeFiles.map((reject_upload: any, index:number) => (

                            <Grid item xs={12} md={6} key={reject_upload.blob_id}>
                              <Box className={classes.downloadBox}>
                                <Box className={classes.downloadInnerBox}>
                                  <img 
                                  alt="dispute file icon" 
                                  className={classes.milestoneGridImage} 
                                  src={workicon}
                                  />
                                  <Typography className={classes.downloadFileName}>{this.convertFileName(reject_upload.name)}</Typography>
                                  <Typography 
                                    data-testid="file_size"
                                    className={classes.downloadFileSize}>
                                    {this.convertFileSize(reject_upload.size)}
                                  </Typography>
                                </Box>

                                   <CardMedia
                                    data-testId="delIcon"
                                    image={deleteFileIcon}
                                    onClick={ this.deleteDisputedFiles.bind(this,index) }
                                    className={classes.deleteFilesIcon}
                                    component="img"
                                />
                              </Box>
                            </Grid>
                          ))
                        } 
                      </Grid>
                     </>
                      {/* ---------------------------------------------------------------------------------- */}
               </Grid>
               <Box className={`${classes.flexClassStyle} ${classes.flexClassAtributesStyle}`}>
                    <Button 
                    className={`${classes.projectCloseButton} ${classes.projectCancelBtnColor}`}
                    disabled={this.state.rejectedLoader}
                    onClick={this.handleOptionCancel}
                    data-testid='cancelRejectBtn'
                        >
                      {configJSON.cancelButton}
                    </Button>
                    <Button 
                        className={`${classes.projectCloseButton} ${classes.projectSubmitBtnColor}`}
                        onClick={this.onSubmitRejectMilestoneDispute.bind(this,milestoneDisputeId)} 
                        data-testid={'submitMilestoneDisputeButton'}
                        disabled={this.state.rejectedLoader && (this.rejectMilestoneDisputeId === milestoneDisputeId.toString())}
                    >
                      {
                      this.state.rejectedLoader && (this.rejectMilestoneDisputeId === milestoneDisputeId.toString())?
                      <CircularProgress  data-testid='rejectMilestoneDisputeLoader' size={24} />
                      :configJSON.submitButton
                      }  
                   </Button>
                    </Box>
              </Grid> 
           } 

   
      </>
}

   renderMilestoneDisputeAccepted = (milestone_dispute_accepted: MilestoneDisputeDetails) => {
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Grid container spacing={1} className={classes.displayDetailsMainBox}>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <CheckCircleOutlineRoundedIcon className={classes.milestoneTitleImage} />
          <Typography className={classes.timelineContentWorkReview}>
            {`${milestone_dispute_accepted.milestone_title} - ${milestone_dispute_accepted.title}`}
            </Typography>
        </Grid>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <Avatar src={milestone_dispute_accepted.profile_pic?.url} className={classes.avatarStyle} />
          <Typography className={classes.timelineAvatarText}>{milestone_dispute_accepted.user_name}</Typography>
        </Grid>
      </Grid>

      <Divider className={classes.divider}></Divider>

      <Typography className={classes.timelineContentDesc} dangerouslySetInnerHTML={{ __html: milestone_dispute_accepted.description.html }} />

      <Box className={classes.timeWrapper}>
        <Typography className={classes.timeStyle} >
          <TimeOnly date={milestone_dispute_accepted.created_at}/>
        </Typography>
      </Box>
    </Grid>
  }

  renderMilestoneDisputeRejected = (milestone_dispute_rejected: MilestoneDisputeDetails) => {
    const { classes } = this.props;
    return <Grid container className={classes.displayDetailGrid}>
      <Grid container spacing={1} className={classes.displayDetailsMainBox}>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <CheckCircleOutlineRoundedIcon className={classes.milestoneTitleImage} />
            <Typography className={classes.timelineContentWorkReview}>
            {`${milestone_dispute_rejected.milestone_title} - ${milestone_dispute_rejected.title}`}
            </Typography>
        </Grid>
        <Grid item xs="auto" className={classes.displayVerifyBox}>
          <Avatar src={milestone_dispute_rejected.profile_pic?.url} className={classes.avatarStyle} />
          <Typography className={classes.timelineAvatarText}>{milestone_dispute_rejected.user_name}</Typography>
        </Grid>
      </Grid>
                                                                                                
         {/* -------------------description reason -------------------------------------- */}
            <Divider className={classes.uploadSubDivider}></Divider>
            <Typography className={classes.descTxtStyle} dangerouslySetInnerHTML={{ __html: milestone_dispute_rejected.description.reason}} />
             {/* -------------------display uploaded files-------------------------------------- */}
    
             {
                  milestone_dispute_rejected.description?.rejection_documents.length > 0 &&
                  <Divider className={classes.uploadSubDivider}></Divider>
                  
             }
                        <Grid container className={classes.downloadDetailsBox}>
                          {
                            milestone_dispute_rejected.description?.rejection_documents.map((dispute_reject:FileDetails) => (

                              <Grid item xs={12} md={6} key={dispute_reject.blob_id}>
                                <Box className={classes.downloadBox}>
                                  <Box className={classes.downloadInnerBox}>
                                    <img 
                                    alt="milestone_dispute" 
                                    className={classes.milestoneGridImage} 
                                    src={workicon} 
                                    />
                                    <Typography
                                     className={classes.downloadFileName}>
                                      {this.convertFileName(dispute_reject.name)}
                                     </Typography>
                                    <Typography data-testid="file_size"
                                      className={classes.downloadFileSize}>
                                      {this.convertFileSize(dispute_reject.size)}
                                    </Typography>
                                  </Box>
                                  <CardMedia
                                    data-testid="file_download"
                                    component="img"
                                    className={classes.downloadImgStyle}
                                    onClick={this.onDownloadFile.bind(this, dispute_reject.url, dispute_reject.name)}
                                    image={download}
                                  />
                                </Box>
                              </Grid>
                            ))
                          }
                        </Grid>
            
             {/* -------------------------- category and amount at milestone level dispute----------------------- */}
      
             <Divider className={classes.divider}></Divider>
             <Typography className={classes.timelineDisputeDesc} dangerouslySetInnerHTML={{ __html: milestone_dispute_rejected.description.html}} />
          
            <Box className={classes.disputeFileTimeWrapper}>
              <Typography className={classes.timeStyle} >
                 <TimeOnly date={milestone_dispute_rejected.created_at}/>
              </Typography>
            </Box>
    </Grid>
}
renderDisputeResumed = (dispute_resume: HistoryDetails )=> {
  const { classes } = this.props;
  const description=getFreelancerMulitCurrencyHTML(dispute_resume.description,dispute_resume?.currency_conversion);
   
  return <Grid container className={classes.displayDetailGrid}>
    <Grid container spacing={1} className={classes.displayDetailsMainBox}>
      <Grid item xs="auto" className={classes.displayVerifyBox}>
        <CheckCircleOutlineRoundedIcon className={classes.milestoneTitleImage} />
        <Typography className={classes.timelineContentWorkReview}>
          {dispute_resume.title}
          </Typography>
      </Grid>

      <Grid item xs="auto" className={classes.displayVerifyBox}>
        <Avatar src={dispute_resume.profile_pic ? dispute_resume.profile_pic.url : ""}  className={classes.avatarStyle} />
        <Typography className={classes.timelineAvatarText}>{dispute_resume.user_name}</Typography>
      </Grid>
    </Grid>

    <Divider className={classes.divider}></Divider>

    <Grid container>
       <Typography className={`${classes.timelineContentDesc} scheduling_history_description resTimelineContent ${classes.timelineDescription} ${classes.marginDescription}`} dangerouslySetInnerHTML={{ __html:description}} ></Typography>
        </Grid> 

    <Box className={classes.timeWrapper}>
      <Typography className={classes.timeStyle} >
       <TimeOnly date={dispute_resume.created_at}/>
      </Typography>
    </Box>
  </Grid>
}

  renderAllFlags=(date:string)=>{
    return <>
           {this.state.milestoneDetails[date].map((item: any) => (
             <React.Fragment key={item}>
               {item.flag_title == this.state.milestoneFlags[0] && this.renderBidAccepted(item)}
               {item.flag_title == this.state.milestoneFlags[1] && this.renderMilestoneCreated(item)}
               {item.flag_title == this.state.milestoneFlags[2] && this.renderMessageSent(item)}
               {item.flag_title == this.state.milestoneFlags[3] && this.renderMessageReceived(item)}
               {item.flag_title == this.state.milestoneFlags[4] && this.renderMileStoneFileUploaded(item)}
               {item.flag_title == this.state.milestoneFlags[5] && this.renderRequestedRevision(item)}
               {item.flag_title == this.state.milestoneFlags[7] && this.renderPaymentInitiated(item)}
               {item.flag_title == this.state.milestoneFlags[8] && this.renderPrjOnHold(item)}
               {item.flag_title == this.state.milestoneFlags[9] && this.renderPrjClosedAccepted(item)}
               {item.flag_title == this.state.milestoneFlags[10] && this.renderPrjClosedRequested(item)}   
               {item.flag_title == this.state.milestoneFlags[11] && this.renderProjectFinished(item)}
               {item.flag_title == this.state.milestoneFlags[12] && this.renderDisputeRaised(item)}
               {item.flag_title == this.state.milestoneFlags[13] && this.renderDisputeAccepted(item)}
               {item.flag_title == this.state.milestoneFlags[14] && this.renderDisputeRejected(item)}
               {item.flag_title == this.state.milestoneFlags[15] && this.renderPrjClosedRejected(item)}
               {this.renderMoreFlags(item)}
               
         </React.Fragment>
        ))}   
    </>
  }
  renderMoreFlags=(item:any)=>{
    return <>
               {item.flag_title ==  this.state.milestoneFlags[16] && this.renderRefundRequested(item)}
               {item.flag_title == this.state.milestoneFlags[17] && this.renderRefundAccepted(item)}
               {item.flag_title == this.state.milestoneFlags[18] && this.renderRefundRejected(item)}
               {item.flag_title ==  this.state.milestoneFlags[19] && this.renderMilestoneDisputeRaised(item)}
               {item.flag_title == this.state.milestoneFlags[20] && this.renderMilestoneDisputeAccepted(item)}
               {item.flag_title == this.state.milestoneFlags[21] && this.renderMilestoneDisputeRejected(item)}
               {item.flag_title == this.state.milestoneFlags[22] && this.renderDisputeResumed(item)}
                
      </>
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;

    const { open, rating } = this.state;
    // Customizable Area End
    return (

      // Customizable Area Start

      <ThemeProvider theme={trackingTheme}>
        {/* -------------------------------- Milestones start------------------------------------------------ */}
        {this.state.detailsLoader ? (
          <Box className={classes.detailLoaderStyle}>
            <CircularProgress size={24} style={{ color: 'grey' }} />
          </Box>
        ) : (
          <>
            {Object.keys(this.state.milestoneDetails).length !== 0 ? (
              <>
                <>
                  {Object.keys(this.state.milestoneDetails).map((date: any) => (
                    <React.Fragment key={date}>
                      <Timeline className={classes.timelineDetailStyle}>
                        <TimelineItem>
                          <TimelineOppositeContent style={{ display: 'none' }} />
                          <TimelineSeparator>
                            <TimelineDot className={classes.timelineDetailDotSubmit}>
                              {date.substr(8, 2)}
                            </TimelineDot>
                            <TimelineConnector className={classes.timelineDetailConectorSubmit} />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Box className={classes.displayDetailBox}>
                              <Typography className={classes.timelineContentHeader}>
                                {moment(date).format('MMMM')}, {moment(date).format('YYYY')}
                              </Typography>
                            </Box>
                              {this.renderAllFlags(date)}
                          </TimelineContent>
                        </TimelineItem>

                      </Timeline>
                    </React.Fragment>
                  ))}
                </>

                  <Box>
                    <Dialog open={open}
                      PaperProps={{
                        style: {
                          borderRadius: '14px',
                        },
                      }}
                    >
                      <Box className={classes.rateUserBox}>

                        <DialogTitle>
                          <Typography className={classes.rateUser}>
                            {configJSON.rateSponser}
                          </Typography>
                        </DialogTitle>
                        <IconButton
                          aria-label="close"
                          onClick={this.handleClose}
                        >

                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                      <DialogContent className={classes.dialogContent}>
                        <Typography
                          className={classes.dialogContentText}
                        >
                          {configJSON.ratingHeaderTxt}
                        </Typography>
                        <Box component="fieldset" borderColor="transparent"
                        >
                          <StarRatings
                            rating={rating}
                            changeRating={this.ratingChanged}
                            starDimension="40px"
                            starSpacing="20px"
                            starRatedColor="orange"
                            starEmptyColor="gray"
                            starHoverColor="orange"

                          />
                        </Box>
                        <Box className="resRatingText" style={{ display: "flex", flexDirection: "row" }}>
                          <Typography
                            className={classes.ratingLabelText}
                            style={{ margin: "0 0 0 0.9rem" }}
                          >
                            {configJSON.ratingPoor}
                          </Typography>
                          <Typography
                            className={classes.ratingLabelText}
                            style={{ margin: "0 0 0 2.7rem" }}
                          >
                             {configJSON.ratingAverage }
                          </Typography>
                          <Typography
                            className={classes.ratingLabelText}
                            style={{ margin: "0 0 0 2.5rem" }}
                          >
                            {configJSON.ratingGood }
                          </Typography>
                          <Typography
                            className={classes.ratingLabelText}
                            style={{ margin: "0 0 0 2rem" }}
                          >
                            {configJSON.ratingVeryGood}
                          </Typography>
                          <Typography
                            className={classes.ratingLabelText}
                            style={{ margin: "0 0 0 1.5rem" }}
                          >
                             {configJSON.ratingExcellent}
                          </Typography>
                        </Box>
                        {/* Implement your rating component here */}
                      </DialogContent>
                      <Box
                        sx={{
                          width: '90%',
                          margin: "0 auto"
                        }}
                      >
                        {this.state.ratingError !== "" && 
                        <Typography className={classes.validateError}>
                          {this.state.ratingError} 
                          </Typography>
                        }

                        <InputLabel className={classes.inputlabelstyle}>{configJSON.anyFeedbackTxt}</InputLabel>
                        <StyledReviewFeedbackField
                          data-testid="anyfeedback"
                          name="name"
                          fullWidth
                          value={this.state.review}
                          className={classes.inputStyle}
                          onChange={this.handleReviewChange}
                          placeholder={configJSON.feedbackPlaceholder}
                        />
                      </Box>
                      <Box
                        className={classes.modalcansubbox}
                      >
                        <Button
                          onClick={this.handleClose}
                          type="submit" 
                          variant="contained"
                          disableElevation
                          className={classes.modalcanelbutton}
                          disabled={this.state.ratingLoader}
                        >
                           {configJSON.cancelButton}
                        </Button>
                        <Button
                           onClick={this.handleValidateRatings}
                          type="submit" 
                          variant="contained"
                          disableElevation
                          className={classes.modalsubmitbutton}
                          disabled={this.state.ratingLoader}
                          data-testid="submitRatings"
                          >
                           {this.state.ratingLoader?
                              <CircularProgress 
                              data-testid='ratingsLoader' 
                              size={24} 
                              />
                         :configJSON.sendButton
                         }
                        </Button>
                      </Box>

                    </Dialog>
                  </Box>
                </>
            ) : (
              <Typography className={classes.errorNoDetails}>{configJSON.errorNoDetailsForMilestones} </Typography>
            )}
          </>
        )}

        {/* -------------------------------- MileStones end------------------------------------------------ */}
      </ThemeProvider>

      // Customizable Area End
    );
  }

}
// Customizable Area Start
export const styles = () => ({

  dialogContentText: {
    color: "#222",
    fontWeight: 700,
    fontSize: "14px",
  } as const,
  modalcansubbox: {
    display: "flex",
    marginBottom: "1rem",
    marginLeft: "16rem",
    marginTop: "1.5rem",
    "@media(max-width: 500px)": {
      marginLeft: 0,
      justifyContent: "center"
    }
  } as const,
  modalcanelbutton: {
    alignItems: "center",
    backgroundColor: "#F2F2F2",
    marginRight: "1rem",
    color: "#222",
    borderRadius: "30px",
    fontSize: "14px",
    cursor: "pointer",
    display: "flex",
    fontWeight: "bold",
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#F2F2F2",
    },
  } as const,
  modalsubmitbutton: {
    alignItems: "center",
    backgroundColor: "#EC952D",
    color: "#fff",
    borderRadius: "30px",
    fontSize: "14px",
    cursor: "pointer",
    display: "flex",
    fontWeight: "bold",
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#EC952D",
    },
  } as const,
  ratingLabelText: {
    color: "#E09945",
    fontWeight: 700,
    fontSize: "12px",
    "@media(max-width:500px)": {
      margin: "0 !important",
    },

  } as const,
  inputlabelstyle: {
    marginTop: "16px",
    color: "#8083A3",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
  } as const,
  inputStyle: {
    color: "#222",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    "&::placeholder": {
      color: "#222",
      fontSize: "14px",
      fontFamily: "Inter",
      fontWeight: 600,
    },
  } as const,
  placeholderStyle: {
    "&::placeholder": {
      color: "#222",
      fontSize: "14px",
      fontFamily: "Inter",
      fontWeight: 600,
    },
  },
  dialogContent: {
    backgroundColor: '#F8EBD8',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflowX: 'hidden',

  } as const,
  rateUser: {
    fontWeight: 700,
    fontSize: "15px",
    marginTop: "0.5rem"
  } as const,
  rateUserBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  } as const,

  shareYourFeedBackReview: {
    alignItems: "center",
    backgroundColor: "#EC952D",
    color: "#fff",
    borderRadius: "30px",
    fontSize: "14px",
    cursor: "pointer",
    display: "flex",
    '&:hover': {
      backgroundColor: "#EC952D",
    },
  } as const,
  timelineDetailDotSubmit: {
    backgroundColor: '#ec952d',
    height: '25px',
    width: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 700,
    marginLeft: '0',
    marginRight: '0',
  },

  timelineContentHeader: {
    color: '#222222',
    fontSize: '16px',
    fontWeight: 700,
    marginRight: '0.7vw'
  },

  timelineContentDesc: {
    color: '#8083a3',
    fontSize: '14px',
    fontWeight: 500,
    width: "100%",
    whiteSpace: 'pre-wrap' as const,
    wordBreak: 'break-word' as const,
  },
  timelineDescription: {
    display:"flex",
    gap:"10px",
    '@media (max-width: 600px)':{
      display:"block",
    }
  },
  displayDetailBox: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0',

  },
  displayVerifyBox: {
    display: 'flex',
    alignItems: 'center',
  },

  displayDetailGrid: {
    display: 'flex',
    alignItems: 'center',
    padding: '2vw',
    borderRadius: '10px',
    backgroundColor: 'rgba(248,248,251,0.7)',
    marginBottom: '1vw',
  },

  milestoneGridImage: {
    width: '20px',
    height: '21px',
    marginRight: '1vw',
  },
  milestoneTitleImage: {
    color: '#ec952d',
    width: '24px',
    height: '24px',
    marginRight: '0.6vw',
  },

        timelineDetailConectorSubmit:{
            borderLeft:'1px dashed #ec952d',
            width: '0',
            backgroundColor:'#fff',
        },

        disputeSubDivider:{
          margin:'16px 0 0 0',
          width:'100%'
      },
        timelineAvatarText:{
            color: '#8083a3',
            fontSize: '12px',
            fontWeight: 600,
          },
        timelineContentWorkReview:{
            color: '#222222',
            fontSize: '14px',
            fontWeight: 700,
        },
   
        timelineDetailStyle:{
            padding: '0px 16px',
        },
     avatarStyle:{
        width: '20px',
        height: '20px',
        marginRight:'1vw',
     },
     timeWrapper:{
        display:'flex',
        alignItems:'center',
         justifyContent:'flex-end',
         width:'100%',
         marginTop:'0.7vw',
        },
    timeStyle: {
         color: "#8083a3",
       fontSize: '10px',
        fontWeight: 500
          },
    displayDetailsMainBox:{
        width:'100%',
        display:'flex',
         alignItems:"center",
        justifyContent:'space-between'
         },
     downloadDetailsBox:{
         display:'flex', 
         alignItems:'center',
        }, 
        downloadBox:{
            marginTop:'16px',
              display:'flex',
              alignItems:'center',
              justifyContent:'space-between',
              padding:'1vw',
              borderRadius: '10px',
             border: '1px solid #e4e6e8',
            backgroundColor:'#ffffff',
            width:'88%',
            '@media (max-width: 350px)':{
              width: "100%"
            }
          },
          downloadInnerBox:{
              display:'flex',
              alignItems:'center',
          },
      
          downloadFileName:{
              color: '#222222',
              fontSize: '12px',
              fontWeight: 600,
              marginRight:'24px',
              whiteSpace: 'nowrap',
              overflow:'hidden',
              textOverflow: 'ellipsis',
              '@media (max-width: 500px)':{
                width: "200px"
              },
              '@media (max-width: 400px)':{
                width: "100px"
              },
              '@media (max-width: 300px)':{
                width: "70px"
              }
          } as const,
          downloadFileSize:{
              color: 'rgba(128,131,163,0.76)',
              fontSize: '10px',
              fontWeight: 500,
          },
          downloadImgStyle:{
            width: '26px',
            height: '26px',
            marginRight:'1vw',
            cursor:'pointer'
        },
        detailLoaderStyle:{ 
            display: 'flex',
             alignItems: 'center', 
             justifyContent: 'center',
              width: '100%'
       },
       errorNoDetails:{
        color:'#222',
        padding :"2vw",
        fontSize: '16px',
        fontWeight: 500,
       }, 
       approvedIconStyle:{
        height:'18px',
        width:'18px',
        marginRight:'10px',
        color:'#d90000',
        }, 

       approvedInfoBox:{
        display:'flex',
       justifyContent:'center'
       },   
       projectCloseButton:{
        display:'flex', 
        alignItems:'center',
        justifyContent:'center',
        boxShadow:'none',
        fontSize: '12px',
        fontWeight: 700,
        margin:'0 5px',
        borderRadius: '30px',
        textTransform: "none" as const,
        color: '#ffffff',
        },
        projectAcceptBtnColor:{
          height:'36px',
          width:'131px', 
          color: '#ffffff',
          backgroundColor:'#16a700',
          '&:hover': {
            backgroundColor: "#16a700",
          },
        }, 
        projectRejectBtnColor:{
          height:'36px',
          width:'131px', 
          color: '#ffffff',
          backgroundColor:'#e84a4a',
          '&:hover': {
            backgroundColor: "#e84a4a",
          },
        },
        projectCancelBtnColor:{
          height:'36px',
          width:'100px', 
          color: '#222',
          backgroundColor:'#f2f2f2',
          '&:hover': {
            backgroundColor: "#f2f2f2",
          },
        },
        projectSubmitBtnColor:{
          height:'36px',
          width:'100px', 
          color: '#fff',
          backgroundColor:'#ec952d',
          '&:hover': {
            backgroundColor: "#ec952d",
          },
        },
      rejectionDetailsContainer:{
          display:'flex', 
          alignItems:'center',
          padding:'2vw',
          borderRadius: '14px',
          border: '1px solid #cfd0d1',
          flexWrap: "wrap" as const,
          margin:'16px 0'
        },  
        rejectionTextAreaStyle:{
          color: "#8083a3",  
          fontSize: '14px',
          fontWeight: 400,
          border:'1px solid #e4e6e8',
          borderRadius:'10px',
          marginLeft:'20px',
          marginBottom:'20px',
          padding:'1vw',
          width: '95%',
         }, 
      flexClassStyle:{
        display:"flex"
      },
      displayNoneStyle:{
        display:'none',
      },
      flexClassAtributesStyle:{
        width:'100%',
        justifyContent:'flex-end',
        marginTop:'32px'
      },
      rejectionFormTitleStyle:{
        marginBottom:'1vw',
        fontWeight:700,
      },
      rejectOptionLabelStyle:{
        color:"#222",
        fontSize: '14px',
        fontWeight: 400,
      },
      uploadDisputeStyle:{
        position:'relative'as const,
          width:'100%',
          height: '123px',
          borderRadius: '10px',
          border: '1.5px dashed #ec952d',
          backgroundColor: 'rgba(236,149,45,0.06)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection:'column' as const,
          margin:"5px 0 2vw 0",
        },
        uploadDisputeTextStyle:{
          color: '#fff',
          fontSize:'12px',
          fontWeight: 500,
          position:"absolute" as const,
          backgroundColor:"#ec952d",
          height:'18px',
          width:'80px',
          padding:'8px 14px',
          borderRadius:'20px',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          marginTop:'-15px'

       },
       uploadDisputeMaxTextStyle:{
        position:"absolute" as const,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        color: "#8083A3",
        fontSize: "10px",
        fontWeight: 500,
        marginTop:'60px',
        height:'18px',
        width:'200px',
     },
       uploadDisputeInputStyle:{
        position:"absolute" as const,
        top:0,
        left:0, 
        height:'100%',
        width:'100%',
        opacity:0, 
        cursor:"pointer",
        },
        optionalTxtStyle:{
          color: "#8083a3",
          fontSize:'14px',
          fontWeight: 700,  
        },
        subDivider:{
          margin:'16px 0 16px 0',
          width:'100%'
      },
      uploadFileDivider:{
        margin:'0',
        width:'100%'
    },
    uploadSubDivider:{
      margin:'16px 0 0 0',
      width:'100%'
  },
  rejectSubDivider:{
    margin:'0 0 16px 0',
    width:'100%'
},
  timelineDisputeDesc:{
    marginTop:'6px',
    color: '#8083a3',
    fontSize: '14px',
    fontWeight: 500,
    whiteSpace: 'pre-wrap' as const,
    wordBreak: 'break-word' as const,
  },
  disputeFileTimeWrapper:{
    display:'flex',
    alignItems:'center',
     justifyContent:'flex-end',
     width:'100%',
    },

    divider: {
      width: '100%',
      margin: '16px 0 16px 0',

  },
  btnDivider:{
    margin:'0 0 16px 0',
    width:'100%'
},
descTxtStyle:{ 
  marginTop:'20px',
  marginBottom:'-10px',
  color: '#8083a3',
  fontSize: '14px',
  fontWeight: 500,
  whiteSpace: 'pre-wrap' as const,
  wordBreak: 'break-word' as const
},
descRejectStyle:{ 
  color: '#8083a3',
  fontWeight: 500,
  whiteSpace: 'pre-wrap' as const,
  wordBreak: 'break-word' as const,
  fontSize: '14px',
},

disputeRaisedStyle:{ 
  marginTop:'10px',
  marginBottom:'-14px',
  wordBreak: 'break-word' as const,
  color: '#8083a3',
  fontSize: '14px',
  fontWeight: 500,
  whiteSpace: 'pre-wrap' as const,
},
deleteFilesIcon:{
  width: '26px',
  height: '26px',
  cursor:'pointer'
},
validateError:{
  padding:'0 16px',
  marginTop:'10px', 
  fontSize:'10px',
  color:"red"
},
marginDescription: {
  marginRight: "6px",
}
});

export default withStyles(styles)(PrjDetails);
// Customizable Area End