import React from 'react';
import Typography from '@material-ui/core/Typography';

const FormError = ({ errors, touched, fieldName }: any) => {
  console.log("touched" , touched)
  console.log("fieldname" , fieldName)

  const isFieldTouched = typeof touched === 'object' ? touched?.[fieldName] : touched;

  if (errors?.[fieldName] && isFieldTouched) {
    return (
      <Typography style={{ paddingTop: 10, color: "#eb020a", fontSize: "14px" }}>
        {errors[fieldName]}
      </Typography>
    );
  }
  return null;
};

export default FormError;
