import React from "react";
import Typography from "@material-ui/core/Typography";

interface MileStoneFormErrorProps {
  errors: Record<string, string | undefined>;
  touched?: Record<string, boolean | undefined>;
  fieldName: string;
}

const MileStoneFormError: React.FC<MileStoneFormErrorProps> = ({ errors, touched = {}, fieldName }) => {
  const errorMessage = errors[fieldName];
  const isFieldTouched = touched[fieldName] ?? false;

  return isFieldTouched && errorMessage ? (
    <Typography style={{ paddingTop: 10, color: "#eb020a", fontSize: "14px" }}>
      {errorMessage}
    </Typography>
  ) : null;
};

export default MileStoneFormError;