import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Button,
  Typography,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core"; 

// Customizable Area Start
import {
  KeyboardBackspace as KeyboardBackspaceIcon,
  Create as CreateIcon
} from "@material-ui/icons";

import {fileIcon } from "./assets";
const MB_FILE_SIZE=1048576;
const getFileSize = (size: number) => {
  const fileSizeInKB = size / 1024;
  const fileSizeInMB = fileSizeInKB / 1024;
  const displaySize = fileSizeInMB >= 1 ? `${fileSizeInMB.toFixed(2)} MB` : `${Math.round(fileSizeInKB)} KB`;
  return displaySize;
};
// Customizable Area End

import ProjecttemplatesController, {
  Props,
  configJSON
} from "./ProjecttemplatesController";

export default class ProjectReviewPage extends ProjecttemplatesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getPostProjectButtonText=()=>{
     if(this.state.reviewProjectDetails.isEditing){
        return configJSON.editProjectBtnTxt;
     }else {
      return configJSON.postProjectBtnTxt;
     }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container maxWidth="lg">
        <Box style={webStyle.topBlockStyle}>
          <Box style={webStyle.headingBlockStyle}>
            <Button
              style={webStyle.backButtonStyle}
              onClick={this.navigateProjectTemplatePage}
            >
              <KeyboardBackspaceIcon style={webStyle.iconBtnStyle} />{" "}
              {configJSON.backBtnTxt}
            </Button>
            <Typography style={webStyle.reviewandPostProjectStyle} className="text-center">
              {configJSON.ReviewandPostProjectTxt}
            </Typography>
          </Box>
          <Button
            variant="text"
            style={webStyle.editBtnStyle}
            onClick={this.navigateProjectTemplatePage}
          >
            <CreateIcon style={webStyle.iconBtnStyle} />
            {configJSON.editBtnTxt}
          </Button>
        </Box>
        <Box style={webStyle.mainContainer}>
          
          <Box style={webStyle.subFieldBlockStyle}>
            <Typography style={webStyle.labelStyle}>
              {configJSON.projetTitleLabelTxt}
            </Typography>
            <Typography style={webStyle.mainLableStyle}>
              {this.state.reviewProjectDetails?.projectName}
            </Typography>
          </Box>
          <Box style={webStyle.subFieldBlockStyle}>
            <Typography style={webStyle.labelStyle}>
              {configJSON.descriptionLabelTxt}
            </Typography>
            <Typography style={webStyle.valueStyle}>
              {this.state.reviewProjectDetails.projectDescription}
            </Typography>
          </Box>
          <Box style={{
            display:"flex",
            alignItems:"center",
            gap:"10px",
            flexWrap:"wrap",
          }}>
          {this.state.reviewProjectDetails.projectUploadeFiles?.filter((item)=>!item?.deleted).map(
            (uploadedFile) => {
              return (
                <Box
                  style={webStyle.fileDetailsBlockStyle}
                  key={uploadedFile.size}
                >
                  <Box style={webStyle.fileSubBlockStyle}>
                    <img
                      src={fileIcon}
                      alt="file"
                      style={webStyle.fileIconStyle}
                    />
                    <Typography style={webStyle.fileNameStyle}>
                      {uploadedFile.name.trim().substring(0,15)}{" "}
                      <span style={webStyle.fileSizeStyle}>
                        {
                          `(${getFileSize(uploadedFile.size)})`
                        }
                      </span>
                    </Typography>
                  </Box>
                  {/* <img
                    src={deleteIcon}
                    style={webStyle.deleteIconStyle}
                    alt="delete"
                  /> */}
                </Box>
              );
            }
          )}
          </Box>
          <Box component="hr" />
          <Typography style={webStyle.mainLableStyle}>
            {configJSON.skillsLabelTxt}
          </Typography>
          <Box style={webStyle.subFieldBlockStyle}>
            <Typography style={webStyle.labelStyle}>
              {configJSON.skillsLabelTxt}
            </Typography>
            <Box style={webStyle.skillsConatinerStyle}>
              {this.state.reviewProjectDetails.selectedProjectSkills.map(
                (skill) => {
                  return (
                    <Typography
                      style={webStyle.singleSkillStyle}
                      key={skill.id}
                    >
                      {skill.name}
                    </Typography>
                  );
                }
              )}
            </Box>
          </Box>
          <Box style={webStyle.subFieldBlockStyle}>
            <Typography style={webStyle.labelStyle}>
              {configJSON.languageLabelTxt}
            </Typography>
            <Typography style={webStyle.valueStyle}>
              {this.state.reviewProjectDetails?.language}
            </Typography>
          </Box>
          <Box component="hr" />
          <Typography style={webStyle.mainLableStyle}>
            {configJSON.scopeLabelTxt}
          </Typography>
          <Box style={webStyle.subFieldBlockStyle}>
            <Typography style={webStyle.labelStyle}>
              {configJSON.projectSizeLabelTxt}
            </Typography>
            <Typography style={webStyle.valueStyle}>
              {this.state.reviewProjectDetails.projectSize}{" "}
              {this.state.reviewProjectDetails.projectDuration}
            </Typography>
          </Box>
          <Box style={webStyle.subFieldBlockStyle}>
            <Typography style={webStyle.labelStyle}>
              {configJSON.freelancerExperienceLabelTxt}
            </Typography>
            <Typography style={webStyle.valueStyle}>
              {this.state.reviewProjectDetails.experienceLevel}
            </Typography>
          </Box>
          <Box component="hr" />
          <Typography style={webStyle.mainLableStyle}>
            {configJSON.budgetLabelTxt}
          </Typography>
          <Box style={webStyle.subFieldBlockStyle}>
            <Typography style={webStyle.labelStyle}>
              {configJSON.estimatedBudgetLabelTxt}
            </Typography>
            <Typography data-testid="project_amount" style={webStyle.valueStyle}>
              {this.state.currentUser?.attributes?.currency_symbol || ""}{this.state.reviewProjectDetails.projectPricetype ===
              "fixed_price"
                ? this.state.reviewProjectDetails.projectAmount
                : `${this.state.reviewProjectDetails.ratePerHour} /hr`}
                {this.state.reviewProjectDetails.bidStatus==="0" && " , Negotiable"}
            </Typography>
          </Box>
          <Box style={webStyle.btnContainerStyle} className="resbtnContainer">{
            !this.state.reviewProjectDetails.isEditing && (
              <Button
                id="btn1"
                style={webStyle.saveasDraftBtnTxt}
                onClick={
                  this.state.reviewProjectDetails.projectView == "3"
                    ? () =>
                        this.updateDraftProject(
                          this.state.reviewProjectDetails.id
                        )
                    : () => this.handlePostProject("3")
                }
              >
                {this.state.isSaveDraftProjectLoading ? <CircularProgress size={24} /> : configJSON.saveasDraftBtnTxt}
              </Button>
            )
          }
            
            <Button
              id="btn2"
              style={webStyle.postProcessBtnTxt}
              onClick={() => this.handlePostProject("1")}
              disabled={this.state.isPostProjectLoading}
            >
              { this.state.isPostProjectLoading ? <CircularProgress size={24} /> : this.getPostProjectButtonText()}
            </Button>
          </Box>
        </Box>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  backButtonStyle: {
    borderRadius: 23,
    background: "#F2F2F2",
    color: "#222",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 14,
    padding: "10 30",
    marginLeft: 10
  },
  reviewandPostProjectStyle: {
    color: "#222",
    fontFamily: "Inter",
    fontSize: 28,
    fontWeight: 700,
    marginLeft: 10
  },
  topBlockStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 40,
    flexWrap: "wrap" as const
  },
  headingBlockStyle: {
    display: "flex",
    rowGap: 10,
    flexWrap: "wrap" as const
  },
  editBtnStyle: {
    textDecoration: "underline",
    display: "flex",
    alignItems: "center",
    color: "#00A7A2",
    fontSize: 16,
    fontWeight: 700
  },
  iconBtnStyle: {
    marginRight: 10,
    fontSize: 16
  },
  mainContainer: {
    borderRadius: 14,
    border: "1px solid #E4E6E8",
    marginTop: 28,
    padding: 30
  },
  mainLableStyle: {
    color: "#222",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 18
  },
  labelStyle: {
    color: "#8083A3",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px"
  },
  valueStyle: {
    color: "#8083A3",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 14,
    marginTop: 10,
    wordBreak: "break-word" as const
  },
  subFieldBlockStyle: {
    marginTop: 30
  },
  fileDetailsBlockStyle: {
    borderRadius: 10,
    border: "1px solid #E4E6E8",
    background: "#F9F9F9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 10px",
    marginTop: 20,
    flexWrap: "wrap" as "wrap"
  },
  fileSubBlockStyle: {
    display: "flex",
    alignItems: "center",
    columnGap: 10
  },
  fileIconStyle: {
    width: 26,
    height: 26
  },
  fileNameStyle: {
    color: "#8083A3",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 600
  },
  fileSizeStyle: {
    color: "#8083A3",
    fontSize: "10px",
    fontFamily: "Inter",
    fontWeight: 600
  },
  deleteIconStyle: {
    borderRadius: "50%",
    border: "1px solid rgba(228, 230, 232, 0.60)",
    background: "#FFF",
    padding: 10
  },
  singleSkillStyle: {
    borderRadius: 30,
    background: "#00A7A2",
    padding: "10 20",
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: 10,
    fontWeight: 500,
    marginTop: 10
  },
  skillsConatinerStyle: {
    display: "flex",
    alignItems: "center",
    columnGap: 10,
    flexWrap:"wrap"
  } as const,
  saveasDraftBtnTxt: {
    borderRadius: 23,
    background: "#F2F2F2",
    color: "#222",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "10 20",
    width: '10rem'
  },
  postProcessBtnTxt: {
    borderRadius: 23,
    backgroundColor: "#00A7A2",
    color: "#fff",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "10 20",
    width:200
  },
  btnContainerStyle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: 100,
    columnGap: 10
  }
};
// Customizable Area End
