import React from 'react';
import {Dialog,DialogTitle,DialogContent,DialogActions,Button,Grid} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuth } from '../../../blocks/rolesandpermissions/src/AuthContext.web';

interface Props{
   open:boolean;
   setOpen:(open:boolean)=>void;
   title:string;
   message:string;
   positiveButtonText?:string;
   negativeButtonText?:string;
   actionLoading?:boolean;
   actionHandler:any;
   actionLoadingText?:string;
   showCancelButton?:boolean;
}
const ActionModal=({
  title,
  message,
  open,
  setOpen,
  positiveButtonText="Yes",
  negativeButtonText="Cancel",
  actionLoading,
  actionHandler,
  showCancelButton=true
}:Props)=>{
  const {user}=useAuth();
  const isLoggedinUserSponsor=user?.currentState?.toLowerCase()?.includes("sponsor");
  const isLoggedinUserFreelancer=user?.currentState?.toLowerCase()?.includes("freelancer");
  let color=isLoggedinUserSponsor?"#00A7A2":"#EB9E00";
  const isActivationMessage = message === "Your account is successfully created. Please activate your account using the activation link sent on your registered Email ID."; 
  if (isActivationMessage) {
    color = ( isLoggedinUserSponsor || isLoggedinUserFreelancer ) ? "#EB9E00" : "#00A7A2";
  }
  if(!user?.currentState){
    color="#f2f2f2";
  }
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        PaperProps={{ style: { borderRadius: 16 } }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          id="max-width-dialog-title"
          style={{ paddingBottom: 0 }}
        >
          <strong>{title}</strong>
        </DialogTitle>
        <DialogContent>
          <p>{message}</p>
        </DialogContent>
        <DialogActions style={{ margin: "15px" }}>
          {
            showCancelButton && (
              <Button
              variant="contained"
              disableElevation
              style={{
                borderRadius: "9px",
                height: "40px",
                width: "100%",
                fontWeight: 600,
                textTransform: "capitalize",
              }}
              onClick={() => setOpen(false)}
            >
              {negativeButtonText}
            </Button>
            )
          }
         
          <Button
            onClick={actionHandler}
            disabled={actionLoading}
            variant="contained"
            disableElevation
            style={{
              borderRadius: "9px",
              height: "40px",
              width: "100%",
              background: 'rgb(236, 149, 45)',
              fontWeight: 600,
              textTransform: "capitalize",
            }}
          >
            {
              actionLoading?<CircularProgress size={20}/>:positiveButtonText
            }
         </Button>
        </DialogActions>
      </Dialog>
    );
}

export default ActionModal;