import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography,Tooltip } from "@material-ui/core";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import InputBase from "@material-ui/core/InputBase";
import AttachmentIcon from "@material-ui/icons/Attachment";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import SendIcon from "@material-ui/icons/Send";
import EmojiPicker from "emoji-picker-react";
import CloseIcon from "@material-ui/icons/Close";
import { IConversation, IMessage } from "../../../blocks/chat/src/ChatController";
import DescriptionIcon from '@material-ui/icons/Description';
import ReactDOM from "react-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chat_input_container: {
      width: "95%",
      margin: "0 auto",
      marginBottom: "10px",
      borderRadius: "23px",
      border: "1px solid #e4e6e8",
      display: "flex",
      alignItems: "center",
      padding: "2px",
      position: "relative",
      "@media(max-width: 480px)": {
        flexDirection: "column",
        alignItems: "end"
      },
    },
    chat_input_box: {
      paddingTop:"10px",
      paddingLeft:"10px",
      lineHeight:"20px",
      alignItems: "center",
    },
    attachment_container: {
      display: "flex",
      alignItems: "center",
    },
    send_button: {
      color: "white",
      transform: "rotate(-45deg)",
      "&:hover": {
        background: "inherit",
      },
    },
    emoji_picker_container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      right: "10%",
      bottom: "75px",
      background: "white",
      border: "1px solid #e4e6e8",
      borderRadius: "10px",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
      zIndex:1301,
      maxHeight:"400px"
    },

    attachment__input: {
      color: "rgba(0, 0, 0, 0.54)",
      padding: "12px",
      fontSize: "1.5rem",
      textAlign: "center",
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderRadius: "50%",
      cursor: "pointer",
      width: "50px",
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transform: "rotate(-45deg)",
      marginBottom: "0px",
      "&:hover": {
        background: "#fafafa",
      },
    },
    emoji_picker_header: {
      display: "flex",
      justifyContent: "flex-end",
    },
    file_container: {
      display: "flex",
      gap: "10px",
      flexWrap: "wrap",
      marginBottom: "10px",
      marginLeft: "10px",
      marginRight: "10px",
    },
    file_item_container: {
      border: "1px solid #e4e6e8",
      padding: "7px",
      borderRadius: "10px",
      display: "flex",
      alignItems: "center",
      height: "80px",
      position: "relative",
      gap:"15px",
      minWidth:"230px",
    },
    file_item_left: {
      width: "80px",
      height: "100%",
      display:"flex",
      alignItems:"center",
      justifyContent:"center"
    },
    file_item_right: {
      marginRight: "10px",
    },
    file_close_button: {
      position: "absolute",
      top: "0px",
      right: "0px",
      cursor: "pointer",
      background: "#fafafa",
      padding: "7px",
    },
    img_file: {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
    replied_container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      right: "10px",
      left: "10px",
      top: "-80px",
      background: "white",
      border: "1px solid #e4e6e8",
      borderRadius: "10px",
      height: "80px",
      overflow:"hidden"
    },
    replied_text_container: {
      padding: "10px 30px",
      height: "70px",
    },
  })
);

export const stripHtmlTags = (html: string) => {
  return html.replace(/<\/?b>|<\/?i>/g, ""); // Removes <b> and <i> tags
};
export const checkIsBold = (text:any) => {
  return /<b>.*<\/b>/.test(text)
}
export const checkIsItalic = (text:any) => {
  return /<i>.*<\/i>/.test(text)
}

interface Props {
  handleSendMessage: (msg: string, documents: any[]) => void;
  repliedMessage: IMessage | null;
  handleSetRepliedMessage: (msg: IMessage | null) => void;
  color?:string;
  edit?:boolean;
  editMessage?:IMessage | null;
  handleRemoveEditMessageAttachment?:(id:number)=>void;
  selectedConversation:IConversation|null;
  sendingError?:boolean;
  setSendingError?:any;
  isBoldState?:boolean;
  isItalicState?:boolean;
  toggleBold:any;
  toggleItalic:any;
}
const ChatInput: React.FC<Props> = ({
  handleSendMessage,
  repliedMessage,
  handleSetRepliedMessage,
  color,
  edit,
  editMessage,
  handleRemoveEditMessageAttachment,
  selectedConversation,
  sendingError,
  setSendingError,
  isBoldState,
  isItalicState,
  toggleBold,
  toggleItalic,
}) => {
  const classes = useStyles();
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const [isBold,setIsBold]=useState(false);
  const [isItalic,setIsItalic]=useState(false);
  const [message, setMessage] = useState("");
  const inputFileRef=React.useRef<HTMLInputElement>(null)

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleChangeEmojiPicker = () => {
    setOpenEmojiPicker((prev) => !prev);
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (selectedFiles) {
      const selectedFilesInArray = Array.from(selectedFiles);
      setFiles((prev) => [...prev, ...selectedFilesInArray]);
    }
  };
  const handleFileClose = (name: string) => {
    const filteredFiles = files.filter((item) => item?.name !== name) || [];
    setFiles(filteredFiles);
  };
  const handleBoldClick = () => {
    if(edit){
      if(isBoldState) {
        toggleBold(false)
      } else if(!isBoldState){
        toggleBold(true)
        setIsBold(true)
      }
    } else setIsBold((prev)=>!prev);
  };
  const handleItalicClick=()=>{
    if(edit){
      if(isItalicState) {
        toggleItalic(false)
      } else if(!isItalicState){
        toggleItalic(true)
        setIsItalic(true)
      }
    } else setIsItalic((prev)=>!prev);
  }
  const handleKeyDown=(e:any)=>{
    if(e?.key==="Enter"){
      sendNewMessage()
    }
  }

  React.useEffect(()=>{
      if(edit && editMessage && !editMessage?.attributes?.deleted){
         setMessage(editMessage?.attributes?.message?.trim())
      }
  },[edit,editMessage])



  const sendNewMessage = () => {
    setSendingError()
    if(edit){
      handleSendMessage(getFormatedText(), files);
      setMessage("")
      setFiles([]);
      setIsBold(false);
      setIsItalic(false);
      if(inputFileRef!=null && inputFileRef.current!==null){
         inputFileRef.current.value="";
      }
      return;
    }
    if ((message.trim()!="" || files?.length>0)) {
     handleSendMessage(getFormatedText(), files);
     setIsBold(false);
      setIsItalic(false);
     setMessage("")
     setFiles([]);
     if(inputFileRef!=null && inputFileRef.current!==null){
        inputFileRef.current.value="";
     }
    }
  };


  const getFormatedText=()=>{
    let text = message;

    const hasBold = /<b>.*<\/b>/g.test(text);
    const hasItalic = /<i>.*<\/i>/g.test(text);

    // Remove existing tags if they are applied
    if (hasBold) {
      text = text.replace(/<\/?b>/g, "");
    }
    if (hasItalic) {
      text = text.replace(/<\/?i>/g, "");
    }

    //Apply formatting
     if(isBold){
       text=`<b>${text}</b>`
     }
     if(isItalic){
      text=`<i>${text}</i>`
     }
     return text;
  }


  const activeColor = color || "#EC952D";

  if(selectedConversation?.attributes?.ticket_status==="resolve"){
    return null;
  }


  return (
    <>
      <div className={classes.chat_input_container}>
        {edit && <InputBase
          style={{
            fontWeight: (isBoldState) ?"bolder":"normal",
            fontStyle: (isItalicState) ?"italic":"normal"
          }}
          ref={inputRef}
          className={classes.chat_input_box}
          // multiline
          maxRows={5}
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={e=>handleKeyDown(e)}
          placeholder="Write here..."
        />}
        {!edit && <InputBase
          style={{
            fontWeight:isBold?"bolder":"normal",
            fontStyle:isItalic?"italic":"normal"
          }}
          ref={inputRef}
          className={classes.chat_input_box}
          // multiline
          maxRows={5}
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={e=>handleKeyDown(e)}
          placeholder="Write here..."
        />}
        <div className={classes.attachment_container}>
          <IconButton onClick={handleItalicClick}>
            <FormatItalicIcon />
          </IconButton>
          <IconButton onClick={handleBoldClick}>
            <FormatBoldIcon />
          </IconButton>
          <label
            className={classes.attachment__input}
            htmlFor={edit?"edit_chat_attchment_input":"chat_attchment_input"}
          >
            <AttachmentIcon />
            <input
              onChange={handleFileChange}
              type="file"
              ref={inputFileRef}
              id={edit?"edit_chat_attchment_input":"chat_attchment_input"}
              style={{ visibility: "hidden", height: "0px", width: "0px" }}
              multiple
            />
          </label>
          <IconButton onClick={handleChangeEmojiPicker}>
            <InsertEmoticonIcon />
          </IconButton>
          <IconButton style={{background:activeColor}} onClick={sendNewMessage} className={classes.send_button}>
            <SendIcon />
          </IconButton>
        </div>
         {openEmojiPicker &&
        ReactDOM.createPortal(
          <div className={classes.emoji_picker_container}>
            <div className={classes.emoji_picker_header}>
              <IconButton onClick={handleChangeEmojiPicker}>
                <CloseIcon />
              </IconButton>
            </div>
            <EmojiPicker
              onEmojiClick={(e: any) => {
                console.log("Emoji", e);
                setMessage((msg) => msg + e?.emoji);
              }}
            />
          </div>,
          document.body // Render emoji modal directly in the body
        )}
        {repliedMessage && (
          <div className={classes.replied_container}>
            <div
              onClick={() => handleSetRepliedMessage(null)}
              className={classes.file_close_button}
            >
              <CloseIcon />
            </div>
            {
              repliedMessage?.attributes?.deleted && 
                (<Typography style={{color:"red",padding:"10px"}}>Deleted</Typography>)
            }
            {
              !repliedMessage?.attributes?.deleted && repliedMessage?.attributes?.message && (
                <Typography
                  noWrap={false}
                  className={classes.replied_text_container}
                >
                  {repliedMessage?.attributes?.message}
                </Typography>
              )
            }
            {
              !repliedMessage?.attributes?.deleted && !repliedMessage?.attributes?.message && repliedMessage?.attributes?.attachments && repliedMessage?.attributes?.attachments?.length > 0 &&(
                <div className={classes.file_container}>
                     {repliedMessage?.attributes?.attachments?.slice(0,2)?.map((item:any) => {
                          const fileName=item?.url?.name>11?item?.url?.name?.substring(0,11)+"...":item?.url?.name;
                          return (
                            <div className={classes.file_item_container}>
                              <div className={classes.file_item_left}>
                                 <DescriptionIcon style={{fontSize:"53px"}}/>
                              </div>
                              <div className={classes.file_item_right}>
                              <Tooltip title={item?.name}>
                                <Typography style={{ fontSize: "12px" }}>
                                  {fileName}
                                </Typography>
                                </Tooltip>
                              </div>
                            </div>
                          );
                        })}
                </div>
              )
            }
          </div>
        )}
      </div>
      <div className={classes.file_container}>
        {
          editMessage && editMessage?.attributes?.attachments && editMessage?.attributes?.attachments?.length>0 && 
          editMessage?.attributes?.attachments?.filter((item:any)=>!item?.deleted).map((item:any)=>{
            const fileName=item?.url?.name || item?.name;
            const fileSize=item?.url?.size || item?.size;
            return(
              <div className={classes.file_item_container}>
              <div className={classes.file_item_left}>
                <DescriptionIcon style={{fontSize:"53px"}}/>
              </div>
              <div className={classes.file_item_right}>
              <Tooltip title={item?.name}>
                <Typography style={{ fontSize: "12px" }}>
                  {fileName}
                </Typography>
                </Tooltip>
                <Typography style={{ fontSize: "10px" }}>
                  {(fileSize / (1024 * 1024)).toFixed(2)} MB
                </Typography>
              </div>

              <div
                onClick={() => handleRemoveEditMessageAttachment && handleRemoveEditMessageAttachment(item?.id)}
                className={classes.file_close_button}
              >
                <CloseIcon />
              </div>
            </div>
            )
          })
        }
        {files?.length > 0 &&
          files.map((item) => {
            const isImageFile = item?.type?.includes("image");
            const fileName=item?.name?.length>11?item?.name?.substring(0,11)+"...":item?.name;
            return (
              <div className={classes.file_item_container}>
                <div className={classes.file_item_left}>
                  {isImageFile ? (
                    <img
                      className={classes.img_file}
                      src={URL.createObjectURL(item)}
                      alt="img"
                    />
                  ) : <DescriptionIcon style={{fontSize:"53px"}}/>}
                </div>
                <div className={classes.file_item_right}>
                <Tooltip title={item?.name}>
                  <Typography style={{ fontSize: "12px" }}>
                    {fileName}
                  </Typography>
                  </Tooltip>
                  <Typography style={{ fontSize: "10px" }}>
                    {(item?.size / (1024 * 1024)).toFixed(2)} MB
                  </Typography>
                </div>

                <div
                  onClick={() => handleFileClose(item?.name)}
                  className={classes.file_close_button}
                >
                  <CloseIcon />
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ChatInput;
