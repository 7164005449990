import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import {toast} from 'react-toastify'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

}
export interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isPasswordReset: boolean;
  email: string;
  password: any;
  FieldError: any;
  error: boolean;
  emailError: any;
  passwordError: any;
  errorEmailSelect: any;
  errorPwdSelect: any;
  forgotError: any;
  isForgot: boolean;
  successMsg: any;
  confirmpassword: any
  emailErrors: {
    emailErr?: string;
  },
  passwordErrors: {
    passwordErr?: string;
    passwordMatchErr?: string;
  },
  shouldRouteToResendPage: boolean;
  showResendLinkStatus: boolean;
  recaptcha:any;
  resetPasswordLinkExpired:boolean;
  resetVisible:{
    visible:boolean;
    confirmVisible:boolean;
  },
  forgetLoader:boolean;
  linkSentLoader:boolean;
  resetTriggerCount:number;
  openDialog:boolean;
  isExpired:boolean;
  // Customizable Area End
}
export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class ForgetPassword extends BlockComponent<
  Props, S, SS
> {

  // Customizable Area Start
  forgetPasswordCallId: any;
  ForgetPasswordId: any
  rsetPasswordCallId: any
  checkLinkExpiryCallApiId: any;

  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isPasswordReset: true,
      email: '',
      password: '',
      FieldError: '',
      error: false,
      emailError: '',
      passwordError: '',
      errorEmailSelect: false,
      errorPwdSelect: false,
      forgotError: '',
      successMsg: '',
      isForgot: true,
      confirmpassword: '',

      emailErrors: {
        emailErr: ''
      },
      passwordErrors: {
        passwordErr: '',
        passwordMatchErr: ''
      },
      shouldRouteToResendPage: false,
      showResendLinkStatus: false,
      recaptcha:'',
      resetPasswordLinkExpired:false,
      resetVisible:{
        visible:false,
        confirmVisible:false,
      },
      forgetLoader:false,
      linkSentLoader:false,
      resetTriggerCount:1,
      openDialog:false,
      isExpired:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentWillMount() {
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      console.log("apiRequestCallId", apiRequestCallId)

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.checkLinkExpiryCallApiId){
        if(responseJson.message==="Link has expired. Please try again from forgot password."){
          this.setState({isExpired:true})
        }
      }

      if (apiRequestCallId === this.forgetPasswordCallId) {
        this.setState({forgetLoader:false})
        const hasData = responseJson.data;
        const hasErrorMessage = responseJson.errors && responseJson.errors[0].message;
        this.responseFactor(hasData,responseJson,hasErrorMessage)
       
      }
      
      if (apiRequestCallId === this.rsetPasswordCallId && responseJson.message === 'Your password reset successully') {
        this.setState({ isPasswordReset: false });
        toast.success(responseJson.message)
      }else if (apiRequestCallId === this.rsetPasswordCallId && responseJson?.error?.length>0) {
        this.setState({ isPasswordReset: true, resetPasswordLinkExpired:true });
        toast.error(responseJson.error[0])
      }
    }
   
    // Customizable Area End
  }
  //web events

  // Customizable Area Start 
responseFactor=(hasData:any,responseJson:any,hasErrorMessage:any)=>{
  if (hasData) {
    localStorage.setItem("reset_password_token", responseJson.data.reset_password_token);
    toast.success(configJSON.statusResendLink);
    if (this.state.shouldRouteToResendPage) {
      this.props.navigation.navigate("ResendLink");
      localStorage.setItem("resendEmail", this.state.email);
    } else {
      this.setState(prev=>({linkSentLoader:false,showResendLinkStatus: true,resetTriggerCount:prev.resetTriggerCount+1 }));
    }
  } else if (hasErrorMessage) {
    this.setState({ emailErrors: { emailErr: responseJson.errors[0].message } });
  }
}

  cancelonClick =  () => {
    localStorage.removeItem('loginSuccessToken')
    this.props.navigation.history.push(`/?email=${this.state.email}`)
  }
  

  validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


    let formErrors: any = {}
    let formIsValid = true

    if (email === '') {
      formIsValid = false
      formErrors["emailErr"] = "Email is required."
    } else if (!emailRegex.test(email)) {
      formIsValid = false
      formErrors["emailErr"] = "Enter a valid email address"
    }

    this.setState({ emailErrors: formErrors })
    return formIsValid
  }
  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email') || "";

    this.checkLinkExpired()
    this.setState({email:email})
  }

  checkLinkExpired = () => {
    const searchParams = new URLSearchParams(window.location?.search);
    const token = searchParams.get('token');
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkLinkExpiryCallApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/reset_redirect?token=${token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  validatePassword = (password: string, confirmPassword: string) => {
    const passwordPattern = /^(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;


    let formErrors: any = {}
    let formIsValid = true

    if (password === '') {
      formIsValid = false
      formErrors["passwordErr"] = configJSON.passwordRequired
    } else if (!passwordPattern.test(password)) {
      formIsValid = false
      formErrors["passwordErr"] = configJSON.invalidPassword
    } else if (password !== confirmPassword) {
      formIsValid = false
      formErrors["passwordMatchErr"] = configJSON.invalidConfirmPassword
    }

    this.setState({ passwordErrors: formErrors })
    return formIsValid
  };

  forgetPassword = (shouldRoute: boolean) => {
    this.state.showResendLinkStatus && this.setState({showResendLinkStatus:false})
    let resendEmail = this.state.email ? this.state.email : localStorage.getItem('resendEmail')
    this.setState({ linkSentLoader:true,shouldRouteToResendPage: shouldRoute })
    const emailValidationResult = shouldRoute ? this.validateEmail(this.state.email) : true;
    if (emailValidationResult) {
      this.setState({forgetLoader:true})
      const header = {
        "Content-Type": "application/json",
      };

      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.forgetPasswordCallId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_login/logins/forgot?email=${resendEmail}`
      );


      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
  }
  Passwordreset = (token?:any) => {
    console.log(token, 'token2**')

    const validationResult = this.validatePassword(this.state.password, this.state.confirmpassword);

    if (validationResult) {

      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get('token');
      const header = {
        "Content-Type": "application/json",
      };
      const raw = JSON.stringify({
        "password": this.state.password || "",
        "password_confirmation": this.state.confirmpassword || ""
      });
      const httpBody = raw

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.rsetPasswordCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_login/logins/reset?token=${token}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header

      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        httpBody
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    }
  }
  onChangeRecaptcha =(value: any)=> {
   this.setState({ recaptcha: value });
  };

  handleResetVisibilityChange=()=>{
    this.setState((prev)=>({
      resetVisible:{
        ...prev.resetVisible,
        visible:!prev.resetVisible.visible
      }
    }))
 }
 getResetDisable=()=>{
  if(this.state.recaptcha === "" || this.state.recaptcha === null || this.state.forgetLoader){
    return true;
  }
  return false;
 }
 
 handleConfirmVisibility=()=>{
   this.setState((prev)=>({
     resetVisible:{
       ...prev.resetVisible,
       confirmVisible:!prev.resetVisible.confirmVisible
     }
   }))
 }

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleLinkExpired = () => {
    this.setState({ isExpired:false });
    this.props.navigation.navigate('SignIn')
  };
  // Customizable Area End
}

